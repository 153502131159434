import { Instance, InstanceInterface } from "@amzn/ask-legal-domain";
import { API as RestAPI } from "aws-amplify";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface InstanceAPI {
    create(input: InstanceInterface.CreateInstanceInput): Promise<APIResponse<Instance>>;
    update(input: InstanceInterface.UpdateInstanceInput): Promise<APIResponse<Instance>>;
    load(id: string): Promise<APIResponse<Instance>>;
    loadAll(): Promise<APIResponse<Instance[]>>;
    getName(instanceId: string): Promise<APIResponse<string>>;
}

export class InstanceAPIImpl implements InstanceAPI {
    constructor (private context: AppContextValue) {}
    async getName (
        instanceId: string
    ): Promise<APIResponse<string>> {
        const response: APIResponse<string> = await RestAPI.post(
            "custom-domain",
            "/instance/get-name",
            toDefaultRequestPayload(instanceId)
        );
        return response;
    }

    async create (
        input: InstanceInterface.CreateInstanceInput
    ): Promise<APIResponse<Instance>> {
        const response: APIResponse<Instance> = await RestAPI.post(
            "custom-domain",
            "/instance/create",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async update (
        input: InstanceInterface.UpdateInstanceInput
    ): Promise<APIResponse<Instance>> {
        const response: APIResponse<Instance> = await RestAPI.post(
            "custom-domain",
            "/instance/update",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async load (id: string): Promise<APIResponse<Instance>> {
        const response: APIResponse<Instance> = await RestAPI.post(
            "custom-domain",
            "/instance/load",
            toDefaultRequestPayload(id)
        );
        return response;
    }

    async loadAll(): Promise<APIResponse<Instance[]>> {
        const response: APIResponse<Instance[]> = await RestAPI.post(
            "custom-domain",
            "/instance/loadAll",
            toDefaultRequestPayload({})
        );
        return response;
    }
}
