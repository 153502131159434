import { DocumentContent, Document, Identity, S3File, InstanceFactory } from "@amzn/ask-legal-domain";
import { SpaceBetween } from "@amzn/awsui-components-react-v3";
import * as React from "react";
import { AppContext } from "../../../../setup/context";
import { UploadFilesComponent } from "./UploadFilesComponent";
import { ViewFilesComponent } from "./ViewFilesComponent";
import { LabelAssignModal } from "../../../label/LabelAssignModal";

export const DocumentUploadFileModalBody = (props: {
    containerId: string;
    content: DocumentContent;
    loading?: boolean;
    update: (updatedContent: DocumentContent) => void;
}) => {
    const context = React.useContext(AppContext);
    const [currentUser, setCurrentUser] = React.useState<Identity>();

    const [attachLabelModalOpenFileKey, setAttachLabelModalOpenFileKey] = React.useState<S3File.AskLegalFileKey>(null);
    const [resourceIdOfUpdatedLabels, setResourceIdOfUpdatedLabels] = React.useState<S3File.AskLegalFileKey>(null);

    const onRemove = (file: Document, groupTitle?: string) => {
        props.update(DocumentContent.removeFile(
            props.content,
            file.filename,
            groupTitle?.length ? groupTitle : undefined
        ));
    };

    const onMove = (file: Document, action: "moveUp" | "moveDown", groupTitle?: string) => {
        props.update(DocumentContent.moveFile(
            props.content,
            file.filename,
            action,
            groupTitle
        ));
    };

    const onManageLabels = (file: Document, groupTitle?: string) => {
        setAttachLabelModalOpenFileKey(file.s3File.key);
        setResourceIdOfUpdatedLabels(file.s3File.key);
    };

    const fetchIdentity = async() => {
        let user: Identity;
        try {
            user = await context.getIdentity();
        } catch (err) {
            console.warn(err);
        }
        if (!user) {
            console.warn("Error fetching user");
            setCurrentUser(null);
        }
        setCurrentUser(user);
    };

    React.useEffect(() => {
        fetchIdentity();
    }, []);

    return (
        <SpaceBetween size={"xl"} direction="vertical">
            {attachLabelModalOpenFileKey &&
                <LabelAssignModal
                    resourceId={S3File.AskLegalFileKey.toString(attachLabelModalOpenFileKey)}
                    resourceType="Document"
                    instanceId={InstanceFactory.fromEntityId(props.containerId)}
                    onCloseModal={() => {
                        setResourceIdOfUpdatedLabels(attachLabelModalOpenFileKey);
                        setAttachLabelModalOpenFileKey(null);
                    }}
                />
            }
            <UploadFilesComponent
                containerId={props.containerId}
                value={props.content}
                onChange={props.update}
                userIdentity={currentUser}
            />
            <ViewFilesComponent
                content={props.content}
                resourceIdOfUpdatedLabels={resourceIdOfUpdatedLabels}
                onRemove={onRemove}
                onMove={onMove}
                onManageLabels={onManageLabels}
                loading={props.loading}
            />
        </SpaceBetween>
    );
};
