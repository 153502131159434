import { EmployeeIdentity, LegalContact, WhosMyLawyerContent } from "@amzn/ask-legal-domain";
import { Box, Button, ExpandableSection, SpaceBetween } from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../../setup/context";
import { UserSearch } from "../../common/UserSearch";
import { LegalContactTable } from "../LegalContactTable";
import { WhosMyLawyerFactory } from "../../../factory/whos-my-lawyer-factory";

export const ViewRecommendationsComp = (props: {
    items: LegalContact.Record[];
    content: WhosMyLawyerContent;
    reloadAction: () => void;
    loading?: boolean;
}) => {
    const context = React.useContext(AppContext);
    const [simulatedUser, setSimulatedUser] = React.useState<EmployeeIdentity>(null);
    const [originalCostCenterId, setOriginalCostCenterId] = React.useState<string>();
    const [costCenterId, setCostCenterId] = React.useState<string>();

    const fetchUserCostCenter = async() => {
        let currentUserIdentity;
        try {
            currentUserIdentity = await context.getIdentityWithCustomFields();
            setOriginalCostCenterId(currentUserIdentity.costCenterId);
        } catch (err) {
            console.warn(err);
        }
        if (!currentUserIdentity) {
            console.warn("Error fetching user");
        }
    };

    React.useEffect(() => {
        if (
            simulatedUser &&
            simulatedUser.employeeDetails?.costCenterId &&
            simulatedUser.employeeDetails.costCenterId.length > 0
        ) {
            setCostCenterId(simulatedUser.employeeDetails.costCenterId);
        } else {
            setCostCenterId(originalCostCenterId);
        }
    }, [simulatedUser, originalCostCenterId]);

    React.useEffect(() => {
        fetchUserCostCenter();
    }, []);

    return (
        <SpaceBetween size="m">
            <ExpandableSection
                defaultExpanded={false}
                variant="footer"
                headerText="Recommendation Simulator"
            >
                <SpaceBetween size="s" direction="horizontal" alignItems="center">
                    <Box textAlign="center" variant="awsui-key-label">View recommendations for</Box>
                    <UserSearch.Single
                        selected={simulatedUser}
                        onUserSelectChange={(s) => setSimulatedUser(s)}
                        includeEmployeeDetails={["costCenterId"]}
                    />
                    {simulatedUser && <Button
                        iconName="refresh"
                        variant="link"
                        onClick={() => setSimulatedUser(null)}
                    >Reset</Button>}
                </SpaceBetween>
            </ExpandableSection>
            <LegalContactTable
                items={props.items
                    .filter(l => l.businessLeaderCostCenterIds
                        ?.find(id => id.costCenterId === costCenterId)
                    )}
                reloadData={props.reloadAction}
                loading={props.loading}
                defaultSortState={WhosMyLawyerFactory.getSortingStateFromContent(props.content)}
                defaultPreference={WhosMyLawyerFactory.toPreferencesFromDisplayConfiguration(
                    props.content.defaultConfiguration
                )}
                headerContent="Recommended Contacts"
                headerDescription={<Box color="text-body-secondary">
                    Legal Contacts are recommended when they are associated with your Business Leader(s)
                </Box>}
                content={props.content}
            />
        </SpaceBetween>
    );
};