import * as React from "react";
import { AppContext } from "../../setup/context";
import { AdminPreferences, APIOutput, Instance } from "@amzn/ask-legal-domain";
import { SearchInputBar } from "../search/SearchInputBar";
import { PreferenceSelectionModal } from "../instance/PreferenceSelectionModal";
import { AppNavigationHeader } from "../navigation/AppNavigationHeader";
import { AppURLFactory } from "../../factory/app-url-factory";
import { Preference } from "../../setup/preference";
import "../../styles/component/layout/AppHeader.scss";
const APP_LOGO = require("../../assets/pathfinder-logo-bw-new.png").default;
const AWS_LEGAL_LOGO = require("../../assets/aws-legal-logo-wb.png").default;
const AMAZON_LEGAL_LOGO = require("../../assets/amazon-legal-logo-wb.png").default;

export interface AppHeaderProps {
    instanceId: string;
    headerType?: "Home" | "Content";
}

/**
 * Responsive Application Header
 */
export const AppHeader = (props: AppHeaderProps) => {
    const context = React.useContext(AppContext);
    const [loadingProps, setLoadingProps] = React.useState<{}>();
    const [errorProps, setErrorProps] = React.useState<Error>();
    const [instanceName, setInstanceName] = React.useState<string>();
    const [orgLogo, setOrgLogo] = React.useState<any>();
    const [preferenceSelectionModalProps, setPreferenceSelectionModalProps] =
        React.useState<React.ComponentProps<typeof PreferenceSelectionModal>>();
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const init = async () => {
        setLoadingProps({});
        setInstanceName(undefined);
        try {
            const loadInstanceOutput = await context.getInstanceAPI().load(props.instanceId);
            const output = APIOutput.fromRaw<Instance>(loadInstanceOutput.data);
            if (output.isOk()) {
                setInstanceName(output.data.name);
                setOrgLogo(
                    getOrganizationLogo(output.data.preferences.organization)
                );
            } else if (output.isErr()) {
                if (output.err.code === 403) {
                    setErrorProps({
                        name: "Forbidden",
                        message: "You do not have permission to access this resource"
                    });
                } else if (output.err.code === 404) {
                    setErrorProps({
                        name: "Not Found",
                        message: "This resource does not exist"
                    });
                } else {
                    setErrorProps({
                        name: "Error",
                        message: output.err.message
                    });
                }
            }
        } catch (error) {
            setErrorProps(error);
        } finally {
            setLoadingProps(undefined);
        }
    };

    const getOrganizationLogo = (org: AdminPreferences.Organization) => {
        switch (org) {
            case AdminPreferences.Organization.AMAZON_LEGAL:
                return AMAZON_LEGAL_LOGO;
            case AdminPreferences.Organization.AWS_LEGAL:
            default:
                return AWS_LEGAL_LOGO;
        }
    };

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const handlePreferenceSelection = () => {
        setPreferenceSelectionModalProps({
            onDismiss: () => setPreferenceSelectionModalProps(undefined)
        });
    };

    React.useEffect(() => {
        init();
    }, [props.instanceId]);

    return <nav className="app-navigation">
        <div className={`nav-container${isMenuOpen ? " active" : ""}`}>
            <div className="nav-left">
                <a href={AppURLFactory.getHome(props.instanceId)}>
                    <img src={APP_LOGO} alt="Pathfinder" className="app-logo" draggable={false} />
                </a>
            </div>

            {instanceName && <div className={`nav-center${isMenuOpen ? " active" : ""}`}>
                <span
                    className="nav-instance-name-action"
                    title="Click here to access other legal orgs"
                    tabIndex={0}
                    onClick={handlePreferenceSelection}
                >
                    {instanceName}
                </span>
                <AppNavigationHeader instanceId={props.instanceId} isMenuOpen={isMenuOpen} />
            </div>}

            {orgLogo && <div className="nav-right">
                <img src={orgLogo} alt="Org Logo" className="org-logo" draggable={false} />
                {props.headerType !== "Home" && <span className="nav-search-bar">
                    <SearchInputBar instanceId={props.instanceId} disabled={!!loadingProps} />
                </span>
                }
                {preferenceSelectionModalProps && <PreferenceSelectionModal {...preferenceSelectionModalProps} />}
            </div>}

            <div className="nav-hamburger" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </nav>;
};