import { Spinner } from "@amzn/awsui-components-react-v3";
import { LegalAiCompanionProps, Question } from "@amzn/legal-ai-companion-types";
import { SkillType } from "@amzn/legal-ai-companion-types";
import { loadComponent } from "@amzn/legal-client-utilities";
import { FunctionComponent, lazy, Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import * as React from "react";
import { AppContext } from "../../setup/context";
import { useInstanceState } from "../instance/instance-store";


const LegalAiComponent = lazy(() =>
  loadComponent("LegalGenAIUIAssets", "./ai-companion", undefined, true)
) as unknown as React.ComponentType<LegalAiCompanionProps>;

const AI_COMPANION_DEFAULT_PAGE_ID: string = "home";

function LegalAiCompanionComponent(props: {
  instanceId: string;
}) {
  const context = React.useContext(AppContext);
  const stageConfig = context.config;
  const { instanceId } = useInstanceState();

  if (!stageConfig.legalAICompanion) return <></>;
  const documentGroupId = stageConfig.legalAICompanion.documentGroupId;

  const instanceConfig = stageConfig.legalAICompanion?.instancesConfig?.find(
    (instanceConfig) =>
      instanceConfig.instanceId.toLowerCase() === instanceId.toLowerCase()
  );

  window["remotes"] = {
    LegalGenAIUIAssets: context.config.legalAICompanion.url
  };

  const legalAISampleQuestions = convertSampleQuestionsToLegalAIQuestions(instanceConfig?.sampleQuestions);

  const legalAiCompanionProps: LegalAiCompanionProps = {
    stage: stageConfig.legalAICompanion.stage,
    currentPageId: AI_COMPANION_DEFAULT_PAGE_ID,
    renderWithPortal: `#ai-companion`,
    configuration: {
      name: "Pathfinder",
      description: "",
      commonSkillsForPage: [SkillType.KnowledgeBase],
      defaultSkillType: SkillType.KnowledgeBase,
      skillConfigurations: {
        [SkillType.KnowledgeBase]: {
          title: "Pathfinder knowledge base",
          description: "I will answer questions from pathfinder knowledge base",
          sampleQuestions: legalAISampleQuestions,
          type: SkillType.KnowledgeBase,
          attributeFilter: {
            "andAllFilters": [
                {
                    "equalsTo": {
                        "name": "instanceId",
                        "value": {
                            "stringValue": instanceId
                        }
                    }
                }
              ]
          },
          documentGroupId: documentGroupId,
          informationPopoverHeader: "Pathfinder knowledge base",
          informationPopoverContent:
            "Answer questions using pathfinder knowledge base",
        },
      },
    },
  };

  return (
    <>
      {(
        <div className={"pathfinder-chatbot"}>
          <ErrorBoundary fallbackRender={({ error }) => {
            return (
              <>
                <div style={{ whiteSpace: "pre-line" }}>Error loading chatbot</div>
                <div style={{ paddingTop: "10px" }}>
                  <p>
                    Please use Report a techinical bug to create SIM
                  </p>
                </div>
              </>
            );
          }}>
            <Suspense fallback={<Spinner />}>
              <LegalAiComponent
                {...legalAiCompanionProps}
                currentPageId={AI_COMPANION_DEFAULT_PAGE_ID}
              />
            </Suspense>
          </ErrorBoundary>
        </div>
      )}
    </>
  );
}

const AiCompanionContentPlaceholderComponent: FunctionComponent = () => {
  useEffect(() => {
    window.dispatchEvent(new CustomEvent("onOpenWindow"));

    return () => {
      window.dispatchEvent(new CustomEvent("onCloseWindow"));
    };
  });

  return <div id={"ai-companion"} style={{height: "100%"}} />;
};

const convertSampleQuestionsToLegalAIQuestions = (sampleQuestions: string[]): Question[] => {
  return sampleQuestions?.map((sampleQuestion) => {
    const legalAIQuestion: Question = {
      prompt: sampleQuestion,
    };

    return legalAIQuestion;
  });
};

export { AiCompanionContentPlaceholderComponent, LegalAiCompanionComponent };