import { Spinner } from "@amzn/awsui-components-react-v3";
import { LegalAiCompanionProps } from "@amzn/legal-ai-companion-types";
import { SkillType } from "@amzn/legal-ai-companion-types";
import { loadComponent } from "@amzn/legal-client-utilities";
import { lazy, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import * as React from "react";
import {
  EntityPermissionOperationNames,
  EntityType,
  IEntityPermission,
} from "@amzn/ask-legal-domain";
import { AppContext } from "../../setup/context";
import { Builder } from "builder-pattern";

const LegalAiComponent = lazy(() =>
  loadComponent("LegalGenAIUIAssets", "./ai-companion")
) as unknown as React.ComponentType<LegalAiCompanionProps>;

const AI_COMPANION_DEFAULT_PAGE_ID: string = "home";

function LegalAiCompanionComponent(props: {
  instanceId: string;
}) {
  const [showChatbot, setShowChatbot] = React.useState<boolean>(false);
  const context = React.useContext(AppContext);
  const stageConfig = context.config;
  if (!stageConfig.legalAICompanion) return <></>;
  const documentGroupId = stageConfig.legalAICompanion.documentGroupId;

  const initRemotes = (url: string): void => {
    window["remotes"] = {
      LegalGenAIUIAssets: url,
    };
  };

  const legalAiCompanionProps: LegalAiCompanionProps = {
    stage: stageConfig.legalAICompanion.stage,
    currentPageId: AI_COMPANION_DEFAULT_PAGE_ID,
    configuration: {
      name: "Pathfinder",
      description: "",
      commonSkillsForPage: [SkillType.KnowledgeBase],
      defaultSkillType: SkillType.KnowledgeBase,
      skillConfigurations: {
        [SkillType.KnowledgeBase]: {
          title: "Pathfinder knowledge base",
          description: "I'll answer questions from pathfinder knowledge base",
          sampleQuestions: [],
          type: SkillType.KnowledgeBase,
          attributeFilter: {
            "andAllFilters": [
                {
                    "equalsTo": {
                        "name": "instanceId",
                        "value": {
                            "stringValue": props.instanceId
                        }
                    }
                }
              ]
          },
          documentGroupId: documentGroupId,
          informationPopoverHeader: "Pathfinder knowledge base",
          informationPopoverContent:
            "Answer questions using pathfinder knowledge base",
        },
      },
    },
  };

  const isUserAdmin = async (instanceId: string): Promise<boolean> => {
    try {
      const isAdminOutput = await context
        .getEntityPermissionAPI()
        .isAuthorized(
          Builder<IEntityPermission.IsAuthorizedInput>()
            .id(instanceId)
            .type(EntityType.Instance)
            .permission(EntityPermissionOperationNames.CAN_ADMIN)
            .build()
        );
      return (isAdminOutput.data as unknown as any).data;
    } catch (e) {
      console.error(`Error checking isAdmin`, e);
      return false;
    }
  };

  const isUserMembertOfRole = async (roleId: string): Promise<boolean> => {
    try {
      const roleMembershipOutput = await context
        .getEntityPermissionAPI()
        .isAuthorized(
          Builder<IEntityPermission.IsAuthorizedInput>()
            .id(roleId)
            .type(EntityType.Role)
            .permission(EntityPermissionOperationNames.CAN_VIEW)
            .build()
        );
      return (roleMembershipOutput.data as unknown as any).data;
    } catch (e) {
      console.error(`Error checking if user is member of role`, e);
      return false;
    }
  };

  const shouldShowChatbot = async (instanceId: string) => {
    try {
      const instanceConfig = stageConfig.legalAICompanion.instancesConfig.find(instanceConfig => instanceConfig.instanceId.toLowerCase() === instanceId.toLowerCase());
      if (instanceConfig) {
        const instancePermissionConfgType = instanceConfig.permissionConfg?.type?.toLowerCase();
        switch (instancePermissionConfgType) {
          case "instance-role":
            const isUserMemberOfRoleResponse = await isUserMembertOfRole(instanceConfig.permissionConfg?.value);
            setShowChatbot(isUserMemberOfRoleResponse);
            break;
          case "instance-admin":
            const isUserAdminResponse = await isUserAdmin(instanceId);
            setShowChatbot(isUserAdminResponse);
            break;
          case "instance-view":
            setShowChatbot(true);
        }
      }
      else {
        setShowChatbot(false);
      }
    } catch (e) {
      console.error(`Error checking if chatbot should be shown`, e);
      setShowChatbot(false);
    }
  };

  React.useEffect(() => {
    if (props.instanceId) {
      shouldShowChatbot(props.instanceId);
    }
  }, [props.instanceId]);

  React.useEffect(() => {
    initRemotes(stageConfig.legalAICompanion.url);
  }, []);

  return (
    <>
      {showChatbot && (
        <div className={"pathfinder-chatbot"}>
          <ErrorBoundary fallbackRender={({ error }) => {
            return (
              <>
                <div style={{ whiteSpace: "pre-line" }}>Error loading chatbot</div>
                <div style={{ paddingTop: "10px" }}>
                  <p>
                    Please use Report a techinical bug to create SIM
                  </p>
                </div>
              </>
            );
          }}>
            <Suspense fallback={<Spinner />}>
              <LegalAiComponent
                {...legalAiCompanionProps}
                currentPageId={AI_COMPANION_DEFAULT_PAGE_ID}
              />
            </Suspense>
          </ErrorBoundary>
        </div>
      )}
    </>
  );
}

export default LegalAiCompanionComponent;
