import { IFile, APIOutput } from "@amzn/ask-legal-domain";
import { API as RestAPI } from "aws-amplify";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface UploadViaPresignedUrlOutput {
    data: APIOutput<AxiosResponse<IFile.PresignedUrlOutput>>;
    statusText: string;
    httpStatusCode: number;
    headers: any;
}

export interface FileAPI {
    getPresignedUrl(input: IFile.PresignedUrlInput): Promise<APIResponse<IFile.PresignedUrlOutput>>;
    uploadViaPresignedUrl(input: {
        data: any;
        presignedUrl: string
    }): Promise<UploadViaPresignedUrlOutput>;
}

export class FileAPIImpl implements FileAPI {
    constructor (private context: AppContextValue) {}

    async getPresignedUrl (
        input: IFile.PresignedUrlInput
    ): Promise<APIResponse<IFile.PresignedUrlOutput>> {
        const response: APIResponse<IFile.PresignedUrlOutput> = await RestAPI.post(
            "custom-domain",
            "/s3-file/getPresignedUrl",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async uploadViaPresignedUrl(input: {
        data: any;
        presignedUrl: string
    }): Promise<UploadViaPresignedUrlOutput> {
        try {
            const req: AxiosRequestConfig = {
                url: input.presignedUrl,
                method: "PUT",
                data: input.data,
            };
            const resp: AxiosResponse = await axios(req);
            return {
                data: APIOutput.createSuccessOutput(resp),
                statusText: resp.statusText,
                httpStatusCode: resp.status,
                headers: resp.headers,
            };
        } catch (e) {
            return {
                data: null,
                httpStatusCode: 400,
                statusText: "Uploading failed",
                headers: null,
            };
        }
    }
}