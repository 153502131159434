import {
  AdvancedList,
  ChoiceOption,
  ItemChoiceOption,
  ItemHyperlink,
  FieldDefinition,
  FieldDefinitionDataType,
  IdentityRef,
  AdvancedListItem
} from "@amzn/altar-sds-client";
import {
  Box,
  Button,
  Checkbox,
  CheckboxProps,
  DatePicker,
  DatePickerProps,
  FormField,
  FormFieldProps,
  Input,
  InputProps,
  SpaceBetween,
  Textarea,
  TextareaProps,
} from "@amzn/awsui-components-react";
import * as React from "react";
import { UserSearch } from "../common/UserSearch";
import {
  EmployeeIdentity,
  FieldConfiguration,
  IAdvancedList,
  IdentityType,
} from "@amzn/ask-legal-domain";
import {
  AdvancedListConstants,
  NumberFormatUtil,
} from "../../utils/advanced-list.constant";
import { NumberFieldView } from "./fields/NumberFields/NumberFieldView";
import { DateFieldView } from "./fields/DateFields/DateFieldView";
import { MultiselectWithFreeText, MultiselectWithFreeTextProps } from "./fields/ChoiceFields/MultiselectWithFreeText";
import { SelectWithFreeText, SelectWithFreeTextProps } from "./fields/ChoiceFields/SingleSelectWithFreeText";
import { HyperLinkEditProps, HyperlinkFieldEdit } from "./fields/HyperlinkFields/HyperlinkFieldEdit";
import { AdvancedListFactory } from "../../factory/advanced-list-factory";
import { SDSUtils } from "../../utils/sds-utils";

function toEmployeeIdentity(
  identityRef?: IdentityRef
): EmployeeIdentity | undefined {
  if (!identityRef) return;

  const ret: EmployeeIdentity = {
    type: IdentityType.Person,
    id: identityRef.id,
    name: identityRef.id,
    email: `${identityRef.id}@amazon.com`
  };

  return ret;
}

export interface UpdateAdvancedListItemCommandInputEditProps {
  item: AdvancedListItem;
  advancedList: AdvancedList;
  fieldConfigurations?: FieldConfiguration.Record;
  value?: Omit<IAdvancedList.UpdateAdvancedListItemInput, "containerId" | "by">;
  onChanged?: (value?: Omit<IAdvancedList.UpdateAdvancedListItemInput, "containerId" | "by">) => void;
}

export function UpdateAdvancedListItemCommandInputEdit(props: UpdateAdvancedListItemCommandInputEditProps) {
  const [formFieldPropsList, setFormFieldPropsList] = React.useState<
    (Pick<FormFieldProps, "label" | "description" | "errorText"> & {
      fieldDef: FieldDefinition;
    })[]
  >(
    props.advancedList.fieldDefinitions.map((e) => ({
      label: e.displayName,
      description: e.description,
      fieldDef: e,
    }))
  );

  const [stringPropsList, setStringPropsList] = React.useState<
      (Pick<TextareaProps, "value" | "disabled"> & { fieldKey: string })[]
  >(
    props.advancedList.fieldDefinitions
      .filter((e) => e.dataType === FieldDefinitionDataType.string)
      .map((e) => ({
        value:
          (props.value?.addOrUpdateValues?.find(
            (v) => v.key === e.fieldKey && v.type === e.dataType
          )?.value as string) ||
          (props.item.values.find((v) => v.key === e.fieldKey)
            ?.value as string) ||
          "",
        fieldKey: e.fieldKey,
        disabled: AdvancedListFactory.isReservedField(e.fieldKey),
      }))
  );

  const [checkboxPropsList, setCheckboxPropsList] = React.useState<
    (Pick<CheckboxProps, "checked" | "disabled"> & { fieldKey: string })[]
  >(
    props.advancedList.fieldDefinitions
      .filter((e) => e.dataType === FieldDefinitionDataType.boolean)
      .map((e) => ({
        checked:
          (props.value?.addOrUpdateValues?.find(
            (v) => v.key === e.fieldKey && v.type === e.dataType
          )?.value as boolean) ||
          (props.item.values.find((v) => v.key === e.fieldKey)
            ?.value as boolean) ||
          false,
        fieldKey: e.fieldKey,
        disabled: AdvancedListFactory.isReservedField(e.fieldKey),
      }))
  );


  const [selectWithFreeTextPropsList, setSelectWithFreeTextPropsList] = React.useState<
      (SelectWithFreeTextProps & { fieldKey: string })[]
  >(
    props.advancedList.fieldDefinitions
      .filter((e) => e.dataType === FieldDefinitionDataType.choice)
      .filter((e) => (props.fieldConfigurations[e.fieldKey] as FieldConfiguration.Choice)?.type
        === FieldConfiguration.ChoiceType.SINGLE
      )
      .map((e) => {
        const existingItemChoiceOption = props.item.values?.find((v) => v.key === e.fieldKey)?.value?.[0] as unknown as ItemChoiceOption;
        const updatedChoiceOption = props.value?.addOrUpdateValues?.find((v) => v.key === e.fieldKey && v.type === e.dataType)?.value as unknown as ItemChoiceOption;
        const originalChoiceOption = props.advancedList?.fieldDefinitions?.find((def) => def.fieldKey === e.fieldKey)?.choiceOptions?.find((choiceOption) => choiceOption.key === existingItemChoiceOption?.key);
        return {
          fieldKey: e.fieldKey,
          selectedOption: (
            updatedChoiceOption || originalChoiceOption || existingItemChoiceOption
          ),
          isFreeTextAllowed: e.allowFreeTextOption,
          options: e.choiceOptions?.map(o => {
            return {
              key: o.key,
              displayValue: o.displayValue
            };
          })
        };
      }
    )
  );

  const [multiSelectWithFreeTextPropsList, setMultiSelectWithFreeTextPropsList] = React.useState<
    (MultiselectWithFreeTextProps & { fieldKey: string })[]
  >(
    props.advancedList.fieldDefinitions
    .filter((e) => e.dataType === FieldDefinitionDataType.choice)
    .filter((e) => (props.fieldConfigurations[e.fieldKey] as FieldConfiguration.Choice)?.type
      === FieldConfiguration.ChoiceType.MULTIPLE
    )
    .map((e) => {
      const updatedChoiceOptions = props.value?.addOrUpdateValues?.find((v) => v.type === e.dataType)?.value as unknown as ItemChoiceOption[];
      const originalChoiceOptions = props.advancedList?.fieldDefinitions
        ?.find((def) => def.fieldKey === e.fieldKey)
        ?.choiceOptions
        ?.filter(
          (choiceOption) =>
            (props.item.values.find((v) => v.key === e.fieldKey)?.value as unknown as ItemChoiceOption[])?.some(
              (v) => v.key === choiceOption.key
            )
        );

      return {
        fieldKey: e.fieldKey!,
        isFreeTextAllowed: e.allowFreeTextOption,
        selectedOptions: (
          updatedChoiceOptions || originalChoiceOptions || []
        ),
        options: [
          ...e.choiceOptions?.map(o => {
            return {
              key: o.key,
              displayValue: o.displayValue
            };
          })
        ]
      };
    })
  );

  const [datePropsList, setDatePropsList] = React.useState<
    (Pick<DatePickerProps, "value" | "placeholder" | "disabled"> & { fieldKey: string })[]
  >(
    props.advancedList.fieldDefinitions
      .filter((e) => e.dataType === FieldDefinitionDataType.date)
      .map((e) => ({
        value:
          (props.value?.addOrUpdateValues?.find(
            (v) => v.key === e.fieldKey && v.type === e.dataType
          )?.value as string) ||
          (props.item.values.find((v) => v.key === e.fieldKey)
            ?.value as string) ||
          "",
        fieldKey: e.fieldKey,
        placeholder: AdvancedListConstants.DEFAULT_DATE_FORMAT,
        disabled: AdvancedListFactory.isReservedField(e.fieldKey),
      }))
  );

  const [numberPropsList, setNumberPropsList] = React.useState<
    (Pick<InputProps, "value" | "disabled"> & { fieldKey: string })[]
  >(
    props.advancedList.fieldDefinitions
      .filter((e) => e.dataType === FieldDefinitionDataType.number)
      .map((e) => ({
        value:
          (props.value?.addOrUpdateValues?.find(
            (v) => v.key === e.fieldKey && v.type === e.dataType
          )?.value as string) ||
          (props.item.values.find((v) => v.key === e.fieldKey)
            ?.value as string) ||
          "",
        fieldKey: e.fieldKey,
        disabled: AdvancedListFactory.isReservedField(e.fieldKey),
      }))
  );

  const [peopleSingleSelectPropsList, setPeopleSingleSelectPropsList] =
    React.useState<
      (Pick<Parameters<typeof UserSearch.Single>[0], "selected" | "disabled"> & {
        fieldKey: string;
      })[]
    >(
      props.advancedList.fieldDefinitions
        .filter((e) => e.dataType === FieldDefinitionDataType.IdentityRef)
        .map((e) => ({
          selected: toEmployeeIdentity(
            (props.value?.addOrUpdateValues?.find(
              (v) => v.key === e.fieldKey && v.type === e.dataType
            )?.value as any as IdentityRef) ||
              (props.item.values.find((v) => v.key === e.fieldKey)
                ?.value as any as IdentityRef)
          ),
          fieldKey: e.fieldKey,
          disabled: AdvancedListFactory.isReservedField(e.fieldKey),
        }))
    );

  const [peopleMultiSelectPropsList, setPeopleMultiSelectPropsList] =
    React.useState<
      (Pick<Parameters<typeof UserSearch.Multiple>[0], "initialSelected" | "disabled"> & {
        fieldKey: string;
      })[]
    >(
      props.advancedList.fieldDefinitions
        .filter((e) => e.dataType === FieldDefinitionDataType.multiIdentityRef)
        .map((e) => ({
          initialSelected:
            props.value?.addOrUpdateValues?.find(
              (v) => v.key === e.fieldKey && v.type === e.dataType
            )?.value ?
              (props.value?.addOrUpdateValues?.find(
                (v) => v.key === e.fieldKey && v.type === e.dataType
              )?.value as any as IdentityRef[]).map(g => toEmployeeIdentity(g)) :
              (props.item.values?.find(
                (v) => v.key === e.fieldKey
              )?.value as any as IdentityRef[])?.map(g => toEmployeeIdentity(g)),
          fieldKey: e.fieldKey,
          disabled: AdvancedListFactory.isReservedField(e.fieldKey),
        }))
    );

  const [hyperlinkPropsList, setHyperlinkPropsList] = React.useState<
    (Pick<HyperLinkEditProps, "value"> & { fieldKey: string })[]
  >(
    props.advancedList.fieldDefinitions
      .filter((e) => e.dataType === FieldDefinitionDataType.hyperlink)
      .map((e) => ({
        fieldKey: e.fieldKey!,
        value: (props.value?.addOrUpdateValues?.find(
          (v) => v.key === e.fieldKey && v.type === e.dataType
        )?.value as any as ItemHyperlink) ||
        (props.item.values.find(
          (v) => v.key === e.fieldKey
        )?.value as any as ItemHyperlink),
      }))
  );

  const sequencePropsList: (Pick<InputProps, "value" | "disabled"> & { fieldKey: string })[] = (
    props.advancedList.fieldDefinitions
      .filter((e) => e.dataType === FieldDefinitionDataType.sequence)
      .map((e) => ({
        value:
          (props.item.values.find((v) => v.key === e.fieldKey)?.value as string) ??
          "Auto generated",
        fieldKey: e.fieldKey,
        disabled: true,
      }))
  );

  function updateHyperlinkPropsValue(fieldKey: string, value?: ItemHyperlink) {
    setHyperlinkPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          value: value,
        };
      })
    );
  }

  function updateCheckboxPropsValue(fieldKey: string, value?: boolean) {
    setCheckboxPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          checked: value,
        };
      })
    );
  }

  function updateDatePropsValue(fieldKey: string, value?: string) {
    setDatePropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          value: value,
        };
      })
    );
  }

  function updateNumberPropsValue(fieldKey: string, value?: string) {
    setNumberPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          value: value,
        };
      })
    );
  }

  function updateStringPropsValue(fieldKey: string, value?: string) {
    setStringPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          value: value,
        };
      })
    );
  }

  function updatePeopleSingleSelectPropsValue(
    fieldKey: string,
    value?: EmployeeIdentity
  ) {
    setPeopleSingleSelectPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          selected: value,
        };
      })
    );
  }


  function updateSelectWithFreeTextPropsValue(fieldKey: string, value?: ItemChoiceOption) {
    setSelectWithFreeTextPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          selectedOption: value,
        };
      })
    );
  }


  function updateMultiSelectWithFreeTextPropsValue(fieldKey: string, value?: ItemChoiceOption[]) {
    setMultiSelectWithFreeTextPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          selectedOptions: value,
        };
      })
    );
  }

  function updatePeopleMultiSelectPropsValue(
    fieldKey: string,
    value?: EmployeeIdentity[]
  ) {
    setPeopleMultiSelectPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          initialSelected: value,
        };
      })
    );
  }

  function updateFormFieldProps(
    fieldKey: string,
    value?: Pick<FormFieldProps, "label" | "errorText">
  ) {
    setFormFieldPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldDef.fieldKey !== fieldKey) return e;
        return {
          ...e,
          ...value,
        };
      })
    );
  }

  function validate() {
    let valid = true;
    for (const stringProps of stringPropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === stringProps.fieldKey
      );
      if (formFieldProps.fieldDef.required && !stringProps.value) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }
      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    for (const checkboxProps of checkboxPropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === checkboxProps.fieldKey
      );
      if (
        formFieldProps.fieldDef.required &&
        typeof checkboxProps === "undefined"
      ) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }
      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    for (const numberProps of numberPropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === numberProps.fieldKey
      );

      if (formFieldProps.fieldDef.required && !numberProps.value) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }

      if (
        numberProps.value &&
        !NumberFormatUtil.numberRegex.test(numberProps.value)
      ) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Not a valid number",
        });
        valid = false;
        continue;
      }
      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    for (const selectWithFreeTextProps of selectWithFreeTextPropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === selectWithFreeTextProps.fieldKey
      );

      if (formFieldProps.fieldDef.required && !selectWithFreeTextProps.selectedOption?.displayValue?.trim()?.length) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }

      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    for (const multiSelectWithFreeTextProps of multiSelectWithFreeTextPropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === multiSelectWithFreeTextProps.fieldKey
      );

      if (formFieldProps.fieldDef.required && !multiSelectWithFreeTextProps.selectedOptions?.length) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }

      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    for (const dateProps of datePropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === dateProps.fieldKey
      );
      if (formFieldProps.fieldDef.required && !dateProps.value) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }
      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    for (const peopleSelectProps of peopleSingleSelectPropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === peopleSelectProps.fieldKey
      );
      if (formFieldProps.fieldDef.required && !peopleSelectProps.selected) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }
      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    for (const hyperlinkProps of hyperlinkPropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === hyperlinkProps.fieldKey
      );
      if (formFieldProps.fieldDef.required && !hyperlinkProps.value) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }
      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    for (const peopleSelectProps of peopleMultiSelectPropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === peopleSelectProps.fieldKey
      );
      if (formFieldProps.fieldDef.required && !peopleSelectProps.initialSelected) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }
      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    let ret: Omit<IAdvancedList.UpdateAdvancedListItemInput, "containerId" | "by"> | undefined;
    if (valid) {
      ret = {
          repositoryId: props.item.entityExtraVersionRef.entityExtraRef.entityRef.repositoryRef.repositoryId,
          entityId: props.item.entityExtraVersionRef.entityExtraRef.entityRef.entityId,
          entityExtraId: props.item.entityExtraVersionRef.entityExtraRef.extraId,
          addOrUpdateValues: [
              ...stringPropsList
                  .filter((e) => e.value)
                  .map((e) => ({
                      key: e.fieldKey,
                      type: FieldDefinitionDataType.string,
                      value: e.value
                  })),
              ...checkboxPropsList
                  .filter((e) => typeof e.checked !== "undefined")
                  .map((e) => ({
                      key: e.fieldKey,
                      type: FieldDefinitionDataType.boolean,
                      value: e.checked
                  })),
              ...selectWithFreeTextPropsList
                .filter((e) => !!e.selectedOption)
                .map((e) => ({
                  key: e.fieldKey,
                  type: FieldDefinitionDataType.choice,
                  value: [{
                    key: e.selectedOption?.key,
                    displayValue: e.selectedOption?.displayValue
                  }]
                })),
              ...multiSelectWithFreeTextPropsList
                .filter((e) => !!e.selectedOptions)
                .map((e) => ({
                  key: e.fieldKey,
                  type: FieldDefinitionDataType.choice,
                  value: e.selectedOptions.map(option => ({
                    key: option.key,
                    displayValue: option.displayValue
                  }))
                })),
              ...datePropsList
                  .filter((e) => e.value)
                  .map((e) => ({
                      key: e.fieldKey,
                      type: FieldDefinitionDataType.date,
                      value: e.value
                  })),
              ...numberPropsList
                  .filter((e) => e.value)
                  .map((e) => ({
                      key: e.fieldKey,
                      type: FieldDefinitionDataType.number,
                      value: Number(e.value)
                  })),
              ...peopleSingleSelectPropsList
                  .filter((e) => e.selected)
                  .map((e) => ({
                      key: e.fieldKey,
                      type: FieldDefinitionDataType.IdentityRef,
                      value: SDSUtils.getAmazonPersonRef(e.selected.id) as any
                  })),
              ...hyperlinkPropsList
                .filter((e) => e.value)
                .map((e) => ({
                  key: e.fieldKey,
                  type: FieldDefinitionDataType.hyperlink,
                  value: {
                    ...e.value,
                    title: e.value.title || e.value.href
                  },
                })),
              ...peopleMultiSelectPropsList
                .filter((e) => e.initialSelected)
                .map((e) => ({
                  key: e.fieldKey,
                  type: FieldDefinitionDataType.multiIdentityRef,
                  value: e.initialSelected.map(v => SDSUtils.getAmazonPersonRef(v.id) as any)
                })),
          ],
          removeValues: [
              ...stringPropsList.filter((e) => !e.value).map((e) => e.fieldKey),
              ...datePropsList.filter((e) => !e.value).map((e) => e.fieldKey),
              ...checkboxPropsList.filter((e) => e.checked === undefined).map((e) => e.fieldKey),
              ...numberPropsList.filter((e) => e.value === undefined).map((e) => e.fieldKey),
              ...selectWithFreeTextPropsList.filter((e) => e.selectedOption === undefined).map((e) => e.fieldKey),
              ...multiSelectWithFreeTextPropsList.filter((e) => e.selectedOptions === undefined).map((e) => e.fieldKey),
              ...peopleSingleSelectPropsList.filter((e) => e.selected === undefined).map((e) => e.fieldKey),
              ...hyperlinkPropsList.filter((e) => e.value === undefined).map((e) => e.fieldKey),
              ...peopleMultiSelectPropsList.filter((e) => e.initialSelected === undefined).map((e) => e.fieldKey)
          ]
      };
    }

    props.onChanged(ret);
  }

  React.useEffect(() => {
    validate();
  }, [
    stringPropsList,
    checkboxPropsList,
    datePropsList,
    numberPropsList,
    peopleSingleSelectPropsList,
    selectWithFreeTextPropsList,
    multiSelectWithFreeTextPropsList,
    hyperlinkPropsList,
    peopleMultiSelectPropsList
  ]);

  return (
    <SpaceBetween size={"s"}>
      {formFieldPropsList.map((formFieldProps) => (
        <FormField {...formFieldProps}>
          {checkboxPropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <Checkbox
                {...e}
                onChange={(event) => {
                  updateCheckboxPropsValue(e.fieldKey, event.detail.checked);
                }}
              />
            ))}
          {selectWithFreeTextPropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <SelectWithFreeText
                {...e}
                onChange={(selectedChoiceOption: ChoiceOption) => {
                  updateSelectWithFreeTextPropsValue(e.fieldKey, selectedChoiceOption);
                }}
              />
            ))
          }
          {multiSelectWithFreeTextPropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <MultiselectWithFreeText
                {...e}
                onChange={(selectedChoiceOptions: ChoiceOption[]) => {
                  updateMultiSelectWithFreeTextPropsValue(e.fieldKey, selectedChoiceOptions);
                }}
              />
            ))
          }
          {datePropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <SpaceBetween direction="vertical" size="xxxs">
                <DatePicker
                  {...e}
                  onChange={(event) => {
                    updateDatePropsValue(e.fieldKey, event.detail.value);
                  }}
                />
                {datePropsList
                  .find((n) => n.fieldKey === e.fieldKey)
                  .value?.trim().length > 0 && (
                    <SpaceBetween direction="horizontal" size="xxxs">
                      <Box variant="awsui-key-label" fontSize="body-s">
                        Preview:
                      </Box>
                      <Box variant="awsui-key-label" fontSize="body-s">
                        <DateFieldView
                          dateFieldValue={datePropsList
                            .find((n) => n.fieldKey === e.fieldKey)
                            .value?.trim()}
                          fieldConfiguration={
                            props.fieldConfigurations[
                              e.fieldKey
                            ] as FieldConfiguration.Date
                          }
                        />
                      </Box>
                  </SpaceBetween>
                )}
              </SpaceBetween>
            ))}
          {stringPropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <Textarea
                {...e}
                onChange={(event) => {
                  updateStringPropsValue(e.fieldKey, event.detail.value);
                }}
              />
            ))}
          {numberPropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <SpaceBetween direction="vertical" size="xxxs">
                <Input
                  {...e}
                  onChange={(event) => {
                    updateNumberPropsValue(e.fieldKey, event.detail.value);
                  }}
                />
                {numberPropsList
                  .find((n) => n.fieldKey === e.fieldKey)
                  .value?.length > 0 && (
                    <SpaceBetween direction="horizontal" size="xxxs">
                      <Box variant="awsui-key-label" fontSize="body-s">
                        Preview:
                      </Box>
                      <Box variant="awsui-key-label" fontSize="body-s">
                        <NumberFieldView
                          numberFieldValue={numberPropsList
                            .find((n) => n.fieldKey === e.fieldKey)
                            .value}
                          fieldConfiguration={
                            props.fieldConfigurations?.[
                              e.fieldKey
                            ] as FieldConfiguration.Number
                          }
                        />
                      </Box>
                  </SpaceBetween>
                )}
              </SpaceBetween>
            ))}
          {peopleSingleSelectPropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <SpaceBetween size="xs" direction="horizontal">
                <UserSearch.Single
                  {...e}
                  onUserSelectChange={(selected) => {
                    updatePeopleSingleSelectPropsValue(e.fieldKey, selected);
                  }}
                />
                <Button
                  onClick={() => {
                    updatePeopleSingleSelectPropsValue(e.fieldKey);
                  }}
                  disabled={e.disabled}
                >
                  Reset
                </Button>
              </SpaceBetween>
            ))}
          {hyperlinkPropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <HyperlinkFieldEdit
                {...e}
                onChange={(event) => {
                  updateHyperlinkPropsValue(e.fieldKey, event);
                }}
              />
            ))
          }
          {peopleMultiSelectPropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <UserSearch.Multiple
                {...e}
                onUserSelectChange={(selected) => {
                  updatePeopleMultiSelectPropsValue(e.fieldKey, selected);
                }}
              />
            ))}
          {sequencePropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => <Input {...e} />)
          }
        </FormField>
      ))}
    </SpaceBetween>
  );
}
