import {
    Cache,
    IRecommendation,
    Recommendation,
    RecommendationRanking,
    ServerSidePaginatedLoadingInput,
    ServerSidePaginatedLoadingOutput
} from "@amzn/ask-legal-domain";
import { API as RestAPI } from "aws-amplify";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface RecommendationAPI {
    create(input: IRecommendation.CreateRecommendationInput): Promise<APIResponse<Recommendation.Data>>;
    update(input: IRecommendation.UpdateRecommendationInput): Promise<APIResponse<Recommendation.Data>>;
    recommend(input: IRecommendation.RecommendInput): Promise<APIResponse<IRecommendation.RecommendOutput>>;
    load(input: string): Promise<APIResponse<Recommendation.Data>>;
    loadAll(
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<Recommendation.Data>>>;
    delete(input: string): Promise<APIResponse<Recommendation.Data>>;
    searchRule(input: IRecommendation.SearchRuleInput): Promise<APIResponse<Recommendation.Data[]>>;
    getFullRank(input: string): Promise<APIResponse<Cache.Data<RecommendationRanking>>>;
    updateRankCheckout(input: string): Promise<APIResponse<Cache.Data<RecommendationRanking>>>;
    updateRankSaveAndCheckin(input: IRecommendation.UpdateRecommendationRankingInput): Promise<APIResponse<Cache.Data<RecommendationRanking>>>;
    updateRankDiscardAndCheckin(input: string): Promise<APIResponse<Cache.Data<RecommendationRanking>>>;
}

export class RecommendationAPIImpl implements RecommendationAPI {
    constructor (private context: AppContextValue) {}
    async getFullRank(input: string): Promise<APIResponse<Cache.Data<RecommendationRanking>>> {
        const response: APIResponse<Cache.Data<RecommendationRanking>> = await RestAPI.post(
            "custom-domain",
            "/recommendation/get-full-ranking",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async updateRankCheckout(input: string): Promise<APIResponse<Cache.Data<RecommendationRanking>>> {
        const response: APIResponse<Cache.Data<RecommendationRanking>> = await RestAPI.post(
            "custom-domain",
            "/recommendation/update-rank-check-out",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async updateRankSaveAndCheckin(
        input: IRecommendation.UpdateRecommendationRankingInput
    ): Promise<APIResponse<Cache.Data<RecommendationRanking>>> {
        const response: APIResponse<Cache.Data<RecommendationRanking>> = await RestAPI.post(
            "custom-domain",
            "/recommendation/update-rank-save-check-in",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async updateRankDiscardAndCheckin(input: string): Promise<APIResponse<Cache.Data<RecommendationRanking>>> {
        const response: APIResponse<Cache.Data<RecommendationRanking>> = await RestAPI.post(
            "custom-domain",
            "/recommendation/update-rank-discard-check-in",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async recommend(input: IRecommendation.RecommendInput): Promise<APIResponse<IRecommendation.RecommendOutput>> {
        const response: APIResponse<IRecommendation.RecommendOutput> = await RestAPI.post(
            "custom-domain",
            "/recommendation/recommend",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async create(input: IRecommendation.CreateRecommendationInput): Promise<APIResponse<Recommendation.Data>> {
        const response: APIResponse<Recommendation.Data> = await RestAPI.post(
            "custom-domain",
            "/recommendation/create",
            toDefaultRequestPayload(input)
        );
        return response;
    }
    async update(input: IRecommendation.UpdateRecommendationInput): Promise<APIResponse<Recommendation.Data>> {
        const response: APIResponse<Recommendation.Data> = await RestAPI.post(
            "custom-domain",
            "/recommendation/update",
            toDefaultRequestPayload(input)
        );
        return response;
    }
    async load(input: string): Promise<APIResponse<Recommendation.Data>> {
        const response: APIResponse<Recommendation.Data> = await RestAPI.post(
            "custom-domain",
            "/recommendation/load",
            toDefaultRequestPayload(input)
        );
        return response;
    }
    async loadAll (
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<Recommendation.Data>>> {
        const response: APIResponse<ServerSidePaginatedLoadingOutput<Recommendation.Data>> = await RestAPI.post(
            "custom-domain",
            "/recommendation/load-all",
            toDefaultRequestPayload(input)
        );
        return response;
    }
    async delete(input: string): Promise<APIResponse<Recommendation.Data>> {
        const response: APIResponse<Recommendation.Data> = await RestAPI.post(
            "custom-domain",
            "/recommendation/delete",
            toDefaultRequestPayload(input)
        );
        return response;
    }
    async searchRule(input: IRecommendation.SearchRuleInput): Promise<APIResponse<Recommendation.Data[]>> {
        const response: APIResponse<Recommendation.Data[]> = await RestAPI.post(
            "custom-domain",
            "/recommendation/search-rule",
            toDefaultRequestPayload(input)
        );
        return response;
    }
}
