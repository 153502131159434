import { EntityExtraVersionRef } from "@amzn/altar-sds-client";
import { APIOutput, ContainerRef, IAdvancedList } from "@amzn/ask-legal-domain";
import {
    Box,
    Button,
    Flashbar,
    FlashbarProps,
    Header,
    Modal,
    SpaceBetween,
    Spinner,
    SpinnerProps
} from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../setup/context";
import { SDSUtils } from "../../utils/sds-utils";

export interface DeleteAdvancedListItemModalProps {
    itemRef: EntityExtraVersionRef;
    containerRef: ContainerRef;
    onDeleted?: (itemRef: EntityExtraVersionRef) => void;
    onCanceled?: () => void;
}

export function DeleteAdvancedListItemModal(props: DeleteAdvancedListItemModalProps) {
    const context = React.useContext(AppContext);
    const [spinnerProps, setSpinnerProps] = React.useState<SpinnerProps>();
    const [flashbarProps, setFlashbarProps] = React.useState<Pick<FlashbarProps, "items">>();

    async function deleteItem() {
        try {
            setSpinnerProps({});

            const deleteItemOutput = await context
                .getAdvancedListAPI()
                .deleteItem({
                    entityId: props.itemRef.entityExtraRef.entityRef.entityId!,
                    repositoryId: props.itemRef.entityExtraRef.entityRef.repositoryRef.repositoryId!,
                    entityExtraId: props.itemRef.entityExtraRef.extraId!,
                    containerId: props.containerRef.id,
                    by: SDSUtils.getAmazonPersonRef(context.userId)
                });

            const output = APIOutput.fromRaw<IAdvancedList.DeleteAdvancedListItemOutput>(deleteItemOutput.data);
            if (output.isErr()) {
                setFlashbarProps({
                    items: [
                        {
                            type: "error",
                            content: output.err.message
                        }
                    ]
                });
            } else {
                setFlashbarProps({
                    items: []
                });
                props.onDeleted?.(props.itemRef);
            }
        } catch (err) {
            setFlashbarProps({
                items: [
                    {
                        type: "error",
                        content: (err as Error).message
                    }
                ]
            });
        } finally {
            setSpinnerProps(undefined);
        }
    }

    return (
        <Modal
            visible={true}
            header={<Header>Delete Item</Header>}
            footer={
                <Box float="right">
                    <SpaceBetween size={"s"} direction="horizontal">
                        <Button
                            onClick={(e) => {
                                props.onCanceled?.();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={(e) => {
                                deleteItem();
                            }}
                        >
                            Confirm
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            {spinnerProps && (
                <Modal visible={true}>
                    <Spinner />
                </Modal>
            )}
            {flashbarProps && <Flashbar {...flashbarProps} />}
            Are you sure you want to delete this item?
        </Modal>
    );
}
