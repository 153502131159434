import * as React from "react";
import { RecentViewsWidget } from "../../components/home-page-widgets/recent-views-widget";
import { NavigationWidget } from "../../components/home-page-widgets/navigation-widget";
import { FavoritesWidget } from "../../components/home-page-widgets/favorites-widget";
import { useAPI, useAPI2 } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import {
    EntityFactory,
    EntityPermissionOperationNames,
    EntityType,
    IEntityPermission,
    IMetrics
} from "@amzn/ask-legal-domain";
import { PermissionDenied } from "../redirects/PermissionDenied";
import { RecommendationWidget } from "../../components/home-page-widgets/recommendation-widget";
import { SearchInputBar } from "../../components/search/SearchInputBar";
import { CaptureAnalyticsComp } from "../../components/analytics/CaptureAnalyticsComp";
import "../../styles/home-page/instance-home-page.scss";
const DEFAULT_BACKGROUND_IMAGE = require("../../assets/instance-home-background-images/default.png").default;
const CPLT_LEGAL_BACKGROUND_IMAGE = require("../../assets/instance-home-background-images/cpltlegal.png").default;
const LATINOS_LEGAL_BACKGROUND_IMAGE = require("../../assets/instance-home-background-images/latinoslegal.png").default;
const ANCHOR_BACKGROUND_IMAGE = require("../../assets/instance-home-background-images/Anchor.png").default;
const MGMSTUDIOS_LEGAL_BACKGROUND_IMAGE = require("../../assets/instance-home-background-images/amazonmgmstudiosLegal.png").default;
const AB_LEGAL_HUB_BACKGROUND_IMAGE = require("../../assets/instance-home-background-images/AB_Legal_Hub_Logo.jpg").default;
const KUIPER_LEGAL_BACKGROUND_IMAGE = require("../../assets/instance-home-background-images/Kuiper_Background.jpg").default;
const ETSL_LEGAL_BACKGROUND_IMAGE = require("../../assets/instance-home-background-images/ETSL_Background.jpg").default;
const NA_STORE_BACKGROUND_IMAGE = require("../../assets/instance-home-background-images/Retail.png").default;
const INTERNATIONAL_STORES_LEGAL_BACKGROUND_IMAGE = require("../../assets/instance-home-background-images/International_stores_legaltech.jpeg").default;
const AB_LEGAL_BACKGROUND_IMAGE = require("../../assets/instance-home-background-images/ABLegalJapan.jpg").default;
const RISC_LEGAL_BACKGROUND_IMAGE = require("../../assets/instance-home-background-images/RISCLegal.png").default;
const CANADA_STORES_BACKGROUND_IMAGE = require("../../assets/instance-home-background-images/canadastores.jpeg").default;

// Below instance has customized home page background image
const CUSTOMIZED_INSTANCE_IMAGES: Record<string, any> = {
    "cpltlegal": CPLT_LEGAL_BACKGROUND_IMAGE,
    "latinoslegal": LATINOS_LEGAL_BACKGROUND_IMAGE,
    "Anchor": ANCHOR_BACKGROUND_IMAGE,
    "AmazonMGMStudiosLegal": MGMSTUDIOS_LEGAL_BACKGROUND_IMAGE,
    "AmazonBusinessLegal": AB_LEGAL_HUB_BACKGROUND_IMAGE,
    "kuiperlegal": KUIPER_LEGAL_BACKGROUND_IMAGE,
    "ETSL": ETSL_LEGAL_BACKGROUND_IMAGE,
    "Retail": NA_STORE_BACKGROUND_IMAGE,
    "Internationalstoreslegaltech": INTERNATIONAL_STORES_LEGAL_BACKGROUND_IMAGE,
    "ABLegalJapan": AB_LEGAL_BACKGROUND_IMAGE,
    "InternationalOneLegal": INTERNATIONAL_STORES_LEGAL_BACKGROUND_IMAGE,
    "RISCLegal": RISC_LEGAL_BACKGROUND_IMAGE,
    "canadastores": CANADA_STORES_BACKGROUND_IMAGE,
};

export const InstanceHome = (props: { id: string }) => {
    const context = React.useContext(AppContext);
    const recordViewHistoryRunner = useAPI(
        context.getCommonAPI().recordViewHistory
    );
    const viewerAccessChecker = useAPI2(
        context.getEntityPermissionAPI().isAuthorized
    );

    React.useEffect(() => {
        viewerAccessChecker.invoke(IEntityPermission.IsAuthorizedInput.create(
            props.id,
            EntityType.Instance,
            EntityPermissionOperationNames.CAN_VIEW
        ));

        recordViewHistoryRunner.submitRun(
            IMetrics.RecordViewHistoryInput.create({
                url: `/instance/${props.id}/home`,
                entityId: props.id,
                type: "Home"
            })
        );
    }, []);

    if (viewerAccessChecker.status === "Error" || (viewerAccessChecker.status === "Succeeded" && !viewerAccessChecker.output)) {
        // view access false, show access denied page
        return <PermissionDenied entityId={props.id} accessType="HomePage"/>;
    }

    let background = DEFAULT_BACKGROUND_IMAGE;
    if (!!CUSTOMIZED_INSTANCE_IMAGES[props.id]) {
        background = CUSTOMIZED_INSTANCE_IMAGES[props.id];
    }

    return (
        <CaptureAnalyticsComp
            entityRef={EntityFactory.fromEntityAttributes(props.id, EntityType.Instance)}
        >
            <div className="instance-home-page-theme">
                <div className="instance-home-widget-theme" style={{
                    backgroundImage: `url(${background})`,
                    backgroundPosition: "top center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}/>
                <div className="instance-home-widget-search-bar">
                    <SearchInputBar
                        instanceId={props.id}
                    />
                </div>
                <div className="px-5">
                    <RecommendationWidget instanceId={props.id}/>
                </div>
                <div className="px-5">
                    <FavoritesWidget instanceId={props.id} />
                </div>
                <div className="px-5">
                    <RecentViewsWidget instanceId={props.id} />
                </div>
                <div className="px-5">
                    <NavigationWidget instanceId={props.id} />
                </div>
            </div>
        </CaptureAnalyticsComp>
    );
};