import {
    AdvancedList,
    EntityExtraVersionRef,
    EntityWorkflowSearchItem,
} from "@amzn/altar-sds-client";
import {
    AdvancedListContent,
    APIOutput,
    IAdvancedList,
    Workflow,
} from "@amzn/ask-legal-domain";
import {
    Flashbar,
    FlashbarProps,
    Table,
    Header,
    ButtonDropdown,
    ButtonDropdownProps,
    TableProps,
    Spinner,
    SpinnerProps,
    Modal,
} from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../../../setup/context";
import {
    WorkflowAddEditModal,
    WorkflowAddEditModalProps,
} from "./workflows/WorkflowAddEditModal";
import { AdvancedListModel } from "../../../../model/advanced-list-model";
import { UIModel } from "../../../../model/ui-model";
import { Builder } from "builder-pattern";
import {
    LoadAllAdvancedListWorkflowsInput,
    LoadAllAdvancedListWorkflowsOutput
} from "../../../../api/advanced-list.api";
import { APIResponse } from "../../../../api/common";
import { SDSUtils } from "../../../../utils/sds-utils";

export function WorkflowList(props: {
    state: UIModel.State<AdvancedListContent>;
    advancedList: AdvancedList;
    onCreated?: (value: Workflow.Payload) => void;
    onDeleted?: (value: Workflow.Payload) => void;
}) {
    const context = React.useContext(AppContext);
    const [spinnerProps, setSpinnerProps] = React.useState<SpinnerProps>();
    const [spinnerProps1, setSpinnerProps1] = React.useState<SpinnerProps>();
    const [flashbarProps, setFlashbarProps] = React.useState<FlashbarProps>();
    const [workflowAddEditModalProps, setWorkflowAddEditModalProps] = React.useState<WorkflowAddEditModalProps>();
    const [loadedWorkflows, setLoadedWorkflows] = React.useState<EntityWorkflowSearchItem[]>([]);
    const [workflows, setWorkflows] = React.useState<AdvancedListModel.WorkflowUIModel[]>([]);
    const [selected, setSelected] = React.useState<AdvancedListModel.WorkflowUIModel[]>([]);

    React.useEffect(() => {
        if (props.advancedList) {
            init();
        } else {
            setSpinnerProps({});
            handleError(`Failed to search for workflows`);
        }
    }, [props.advancedList]);

    React.useEffect(() => {
        // Update workflows list
        const liveWorkflowList = loadedWorkflows
            // filter out live workflows with existing drafts
            .filter(w => !props.state.value.upsertWorkflowList
                .find(u => u.ref?.entityExtraRef?.extraId === w.ref.entityExtraRef.extraId)
            )
            .map(w => AdvancedListModel.WorkflowUIModel.create({
                id: w.ref.entityExtraRef.extraId,
                name: w.name,
                ref: w.ref,
                disabled: w.disabled,
                sourceType: "live"
            }));

        const draftWorkflowList = props.state.value.upsertWorkflowList
            .filter(w => !liveWorkflowList.some(x => x.id === w.id))
            .map(w => AdvancedListModel.WorkflowUIModel.create({
                id: w.id,
                name: w.name,
                ref: w.ref,
                disabled: w.disabled,
                sourceType: "draft"
            }));
        setWorkflows([
            ...draftWorkflowList,
            ...liveWorkflowList
        ]);
    }, [
        props.state.value.upsertWorkflowList,
        props.state.value.deleteWorkflowList,
        loadedWorkflows
    ]);

    const init = async () => {
        try {
            setSpinnerProps({});
            const loadAllWorkflowsOutput = await loadAllWorkflows();
            handleLoadlAllWorkflowsResponse(loadAllWorkflowsOutput);
        } catch (err) {
            handleError(err.message);
        } finally {
            setSpinnerProps(undefined);
        }
    };

    const loadAllWorkflows = async () => {
        return await context.getAdvancedListAPI().loadAllAdvancedListWorkflows(
            Builder<LoadAllAdvancedListWorkflowsInput>()
                .listRef(props.advancedList.entityInfo.ref.entityRef)
                .by(SDSUtils.getAmazonPersonRef(context.userId))
                .build()
        );
    };

    const handleLoadlAllWorkflowsResponse = (loadAllWorkflowsOutput: APIResponse<LoadAllAdvancedListWorkflowsOutput>) => {
        const output = APIOutput.fromRaw<LoadAllAdvancedListWorkflowsOutput>(loadAllWorkflowsOutput.data);
        if (output.isErr()) {
            setFlashbarProps({
                items: [
                    {
                        type: "error",
                        content: output.err.message,
                    },
                ],
            });
        } else {
            setFlashbarProps({
                items: [],
            });
            setLoadedWorkflows(output.data.body);
        }
    };

    const editWorkflow = async () => {
        if (!selected.length) return;
        try {
            setSpinnerProps1({});
            const loadWorkflowOutput = await loadWorkflow(selected[0].ref);
            handleLoadWorkflowResponse(loadWorkflowOutput);
        } catch (err) {
            handleError(`Failed to load workflow: ${err.message}`);
        } finally {
            setSpinnerProps1(undefined);
        }
    };

    const loadWorkflow = async (workflowRef: EntityExtraVersionRef) => {
        return await context.getAdvancedListAPI().loadWorkflow(
            Builder<IAdvancedList.LoadAdvancedListWorkflowInput>()
                .workflowRefs([workflowRef])
                .by(SDSUtils.getAmazonPersonRef(context.userId))
                .build()
        );
    };

    const handleLoadWorkflowResponse = (response: APIResponse<IAdvancedList.LoadAdvancedListWorkflowOutput>) => {
        const output = APIOutput.fromRaw<IAdvancedList.LoadAdvancedListWorkflowOutput>(response.data);
        if (output.isErr()) {
            handleError(output.err.message);
        } else {
            setFlashbarProps({
                items: [],
            });
            setWorkflowAddEditModalProps({
                advancedList: props.advancedList,
                workflowPayload: Workflow.Payload.fromEntityWorkflow(
                    output.data.body[0]
                ),
                fieldConfigurations: props.state.value.fieldConfigurations
            });
        }
    };

    const handleError = (message: string) => {
        setFlashbarProps({
            items: [
                {
                    type: "error",
                    content: message,
                },
            ],
        });
    };

    const addWorkflow = () => {
        setWorkflowAddEditModalProps({
            advancedList: props.advancedList,
            fieldConfigurations: props.state.value.fieldConfigurations,
        });
    };

    const actionsButtonDropdown = () => {
        const items: ButtonDropdownProps.Items = [
            {
                text: "Edit Workflow",
                id: "edit",
                disabled: selected.length !== 1
            },
            {
                text: "Delete Workflow",
                id: "delete",
                disabled: selected.length !== 1
            },
        ];

        const mainAction: ButtonDropdownProps.MainAction = {
            text: "Add Workflow",
            onClick: () => {
                addWorkflow();
            }
        };

        const onClickHandler = (action: string) => {
            switch (action) {
                case "edit":
                    if (selected[0].sourceType === "draft") {
                        setWorkflowAddEditModalProps({
                            advancedList: props.advancedList,
                            workflowPayload: props.state.value.upsertWorkflowList.find(w => w.id === selected[0].id),
                            fieldConfigurations: props.state.value.fieldConfigurations
                        });
                    } else if (selected[0].sourceType === "live") {
                        editWorkflow();
                    }
                    break;
                case "delete":
                    props.state.setValue(
                        AdvancedListModel.deleteWorkflowModel(props.state.value, selected[0])
                    );
                    setSelected([]);
                    break;
                default:
                    alert("Unknown action detected!");
                    break;
            }
        };

        return <ButtonDropdown
            items={items}
            variant="primary"
            mainAction={mainAction}
            onItemClick={(event: { detail: { id: string } }) => onClickHandler(event.detail.id)}
            disabled={selected.length !== 1}
        />;
    };

    const tableProps: TableProps<AdvancedListModel.WorkflowUIModel> = {
        items: workflows,
        selectedItems: selected,
        selectionType: "single",
        onSelectionChange: event => {
            setSelected(event.detail.selectedItems);
        },
        header: <Header actions={actionsButtonDropdown()}>Manage Workflows</Header>,
        columnDefinitions: [
            {
                id: "name",
                header: "Name",
                cell: item => {
                    let suffix = "";
                    if (props.state.value.upsertWorkflowList.some(w => w.id === item.id)) {
                        suffix = " (pending)";
                    } else if (props.state.value.deleteWorkflowList?.some(w => w.id! === item.id)) {
                        suffix = " (pending delete)";
                    }
                    return `${item.name}${suffix}`;
                },
            },
            {
                id: "active",
                header: "Active",
                cell: item => item.disabled ? "No" : "Yes",
            }
        ],
        loading: !!spinnerProps,
        isItemDisabled: item => props.state.value.deleteWorkflowList?.some(x => {
            if (x.ref?.entityExtraRef?.extraId === undefined || item.ref?.entityExtraRef?.extraId === undefined) return false;
            return x.ref?.entityExtraRef?.extraId === item.ref?.entityExtraRef?.extraId;
        }),
        trackBy: item => item.id
    };

    return (
        <>
            {flashbarProps && <Flashbar {...flashbarProps} />}
            {!!spinnerProps1 && (
                <Modal visible={true}>
                    <Spinner />
                </Modal>
            )}
            {workflowAddEditModalProps && (
                <WorkflowAddEditModal
                    {...workflowAddEditModalProps}
                    onCancel={() => {
                        setWorkflowAddEditModalProps(undefined);
                    }}
                    onCreated={(createdWorkflow: Workflow.Payload) => {
                        props.onCreated?.(createdWorkflow);
                        setWorkflowAddEditModalProps(undefined);
                    }}
                />
            )}
            <Table {...tableProps} />
        </>
    );
}
