import { Label, S3File, ServerSidePaginatedLoadingInput, Document } from "@amzn/ask-legal-domain";
import { Badge, Grid, Spinner } from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI2 } from "../../../../hooks/api-hook";
import { AppContext } from "../../../../setup/context";
import { DocumentIcon } from "../../../common/DocumentIcon";

export const DocumentContentComp = (props: {
    document: Document;
    loading: boolean;
    reload: boolean;
    groupTitle?: string;
    actions: JSX.Element;
}) => {
    const context = React.useContext(AppContext);
    const getLabelsByResourceRunner = useAPI2(
        context.getLabelAPI().getLabelsByResource
    );
    const [labels, setLabels] = React.useState<Array<Label.Data>>([]);

    const getLabelsByResource = () => {
        getLabelsByResourceRunner.invoke(
            ServerSidePaginatedLoadingInput.create({
                partitionKey: S3File.AskLegalFileKey.toString(props.document.s3File.key),
                currentPageIndex: 1,
                pageSize: 100,
                filters: []
            })
        );
    };

    React.useEffect(() => {
        getLabelsByResource();
    }, [props.document]);

    React.useEffect(() => {
        if (getLabelsByResourceRunner.status !== "Running" && props.reload) {
            getLabelsByResource();
        }
    }, [props.reload]);

    React.useEffect(() => {
        if (getLabelsByResourceRunner.status === "Succeeded" && getLabelsByResourceRunner.output.totalCount > 0) {
            setLabels(getLabelsByResourceRunner.output.result);
        }
    }, [getLabelsByResourceRunner.status]);

    return <Grid gridDefinition={[
        { colspan: 6 },
        { colspan: 3 },
        { colspan: 3 }
    ]}>
        <div className="document-upload-flex">
            <DocumentIcon filename={props.document.filename} />
            <div className="document-info">
                <h6>{props.document.filename}</h6>
                <p>{props.document.description}</p>
            </div>
        </div>
        <div>
            {getLabelsByResourceRunner.status === "Running" && <Spinner />}
            {getLabelsByResourceRunner.status === "Succeeded" && labels.map(l => <Badge color="grey">{l.name}</Badge>)}
        </div>
        {props.actions}
    </Grid>;
};