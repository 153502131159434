import {
    AdvancedListItem,
    AdvancedListItemAttachment,
    EntityExtraVersionRef,
} from "@amzn/altar-sds-client";
import {
    Badge,
    Button,
    Flashbar,
    FlashbarProps,
    Header,
    Link,
    SpaceBetween,
    Table,
    TableProps
} from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../../setup/context";
import { useAPI2 } from "../../../hooks/api-hook";
import { UIModel } from "../../../model/ui-model";
import { SDSUtils } from "../../../utils/sds-utils";

export const AttachmentTable = (props: {
    item: AdvancedListItem;
    itemRef: EntityExtraVersionRef;
    editing?: boolean;
    filesPendingDelete?: UIModel.State<string[]>;
}) => {
    const context = React.useContext(AppContext);
    const [flashbarProps, setFlashbarProps] = React.useState<Pick<FlashbarProps, "items">>();
    const [selectedItems, setSelectedItems] = React.useState<AdvancedListItemAttachment[]>([]);

    const downloadItemAttachmentApiRunner = useAPI2(
        context.getAdvancedListAPI().downloadItemAttachment
    );

    const downloadAttachmentFile = (key: string) => {
        downloadItemAttachmentApiRunner.invoke({
            repositoryId: props.itemRef.entityExtraRef.entityRef.repositoryRef.repositoryId,
            entityId: props.itemRef.entityExtraRef.entityRef.entityId,
            entityExtraId: props.itemRef.entityExtraRef.extraId,
            key: key,
            by: SDSUtils.getAmazonPersonRef(context.userId)
        });
    };

    const PendingDeleteBadge = (item: AdvancedListItemAttachment) => {
        return <span>
            <Badge color="red">
                Marked for deletion
            </Badge>
            <Button
                variant="icon"
                iconName="close"
                onClick={() => props.filesPendingDelete.setValue(props.filesPendingDelete.value.filter(x => x !== item.key))}
            />
        </span>;
    };

    const columnDefinitions: TableProps.ColumnDefinition<AdvancedListItemAttachment>[] = [{
        id: "filename",
        header: "Filename",
        cell: item => <SpaceBetween size="s" direction="horizontal">
            {props.filesPendingDelete?.value.includes(item.key) && PendingDeleteBadge(item)}
            <Link onFollow={() => downloadAttachmentFile(item.key)} >
                {item.fileName}
            </Link>
        </SpaceBetween>,
    }];

    const tableProps: TableProps<AdvancedListItemAttachment> = {
        columnDefinitions: columnDefinitions,
        items: props.item.attachments || [],
        selectedItems: selectedItems,
        selectionType: props.editing ? "multi" : undefined,
        onSelectionChange: ({ detail }) => setSelectedItems(detail.selectedItems),
        sortingDisabled: true,
        enableKeyboardNavigation: true,
        renderAriaLive: ({ totalItemsCount }) =>
            `Displaying ${totalItemsCount} attachments`,
        loadingText: "Loading attachments",
        contentDensity: "compact",
        variant: "borderless",
        trackBy: "key",
        header: <Header
            actions={
                props.editing && <Button
                    onClick={() => {
                        props.filesPendingDelete.setValue([
                            ...props.filesPendingDelete.value,
                            ...selectedItems.filter(x => !props.filesPendingDelete.value.includes(x.key)).map(x => x.key!)
                        ]);
                        setSelectedItems([]);
                    }}
                    variant="primary"
                    iconName="remove"
                    disabled={!selectedItems.length || selectedItems.every(x => props.filesPendingDelete.value.includes(x.key!))}
                > Delete</Button>
            }>
                Attachments
        </Header>,
        empty: "No attachments",
    };

    React.useEffect(() => {
        if (downloadItemAttachmentApiRunner.status === "Succeeded") {
            window.open(downloadItemAttachmentApiRunner.output.downloadUrl, "_blank");
            setFlashbarProps({
                items: []
            });
            downloadItemAttachmentApiRunner.resetStatus();
        } else if (downloadItemAttachmentApiRunner.status === "Error") {
            setFlashbarProps({
                items: [
                    {
                        type: "error",
                        content: downloadItemAttachmentApiRunner.err.message
                    }
                ]
            });
        }
    }, [downloadItemAttachmentApiRunner]);

    return <SpaceBetween size="m">
        {flashbarProps && <Flashbar {...flashbarProps} />}
        <Table {...tableProps} />
    </SpaceBetween>;
};