import * as React from "react";
import { Select, SpaceBetween, FormField, Checkbox, Header } from "@amzn/awsui-components-react";
import { AdvancedListContent } from "@amzn/ask-legal-domain";
import { UIModel } from "../../../../../model/ui-model";
import { AdvancedList } from "@amzn/altar-sds-client";

export const DefaultSort = (props: {
  state: UIModel.State<AdvancedListContent>;
  advancedList: AdvancedList;
}) => {
  const [defaultSort, setDefaultSort] = React.useState(
    props.state?.value?.updateAdvancedListPayload?.defaultSort ??
    props.advancedList?.defaultSort ??
    { propertyName: "id", descending: false }
  );

  React.useEffect(() => {
    props.state.setValue(
      AdvancedListContent.updateDefaultSort({
        content: props.state.value,
        defaultSort,
        fieldDefinitions: props.advancedList?.fieldDefinitions,
      })
    );
  }, [defaultSort]);

  return (
    <SpaceBetween direction="vertical" size="s">
      <Header variant="h2">Sort</Header>
      <FormField>
        <SpaceBetween size="s" direction="horizontal">
          <Select
            options={props.advancedList?.fieldDefinitions
              ?.filter((f) => !f.deprecated)
              .map((field) => ({
                value: field.fieldKey,
                label: field.displayName,
              })) || []
            }
            selectedOption={
              defaultSort
                ? {
                  value: defaultSort.propertyName,
                  label: props.advancedList?.fieldDefinitions?.find(
                    (field) => field.fieldKey === defaultSort.propertyName
                  )?.displayName || defaultSort.propertyName,
                }
                : undefined
            }
            onChange={({ detail }) => {
              const selectedFieldKey = detail.selectedOption.value;
              if (defaultSort?.propertyName === selectedFieldKey) return;
              setDefaultSort(
                selectedFieldKey
                  ? { propertyName: selectedFieldKey, descending: false }
                  : { propertyName: "id", descending: false }
              );
            }}
            placeholder="Select field"
            filteringType="auto"
          />
          <Checkbox
            disabled={!defaultSort}
            onChange={({ detail }) => {
              setDefaultSort((prevSort) => ({
                ...prevSort,
                descending: detail.checked,
              }));
            }}
            checked={defaultSort?.descending ?? false}
          >
            Descending
          </Checkbox>
        </SpaceBetween>
      </FormField>
    </SpaceBetween>
  );
};