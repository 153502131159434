import {
    ComparisonOperator,
    EntityRef,
    EntityWorkflowSearchItem,
    IdentityRef
} from "@amzn/altar-sds-client";
import { APIOutput, IAdvancedList } from "@amzn/ask-legal-domain";
import { API as RestAPI } from "aws-amplify";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface LoadAllAdvancedListWorkflowsInput {
    listRef: EntityRef;
    by: IdentityRef;
}

export interface LoadAllAdvancedListWorkflowsOutput {
    body: EntityWorkflowSearchItem[];
}

export interface AdvancedListAPI {
    load(input: IAdvancedList.LoadAdvancedListInput): Promise<APIResponse<IAdvancedList.LoadAdvancedListOutput>>;
    createItem(input: IAdvancedList.CreateAdvancedListItemInput): Promise<APIResponse<IAdvancedList.CreateAdvancedListItemOutput>>;
    loadItem(input: IAdvancedList.LoadAdvancedListItemInput): Promise<APIResponse<IAdvancedList.LoadAdvancedListItemOutput>>;
    deleteItem(input: IAdvancedList.DeleteAdvancedListItemInput): Promise<APIResponse<IAdvancedList.DeleteAdvancedListItemOutput>>;
    updateItem(input: IAdvancedList.UpdateAdvancedListItemInput): Promise<APIResponse<IAdvancedList.UpdateAdvancedListItemOutput>>;
    searchItems(input: IAdvancedList.SearchAdvancedListItemsInput): Promise<APIResponse<IAdvancedList.SearchAdvancedListItemsOutput>>;
    batchUploadItemAttachment(
        input: IAdvancedList.BatchUploadAdvancedListItemAttachmentInput
    ): Promise<APIResponse<IAdvancedList.BatchUploadAdvancedListItemAttachmentOutput>>;
    batchDeleteItemAttachment(
        input: IAdvancedList.BatchDeleteAdvancedListItemAttachmentInput
    ): Promise<APIResponse<IAdvancedList.BatchDeleteAdvancedListItemAttachmentOutput>>;
    downloadItemAttachment(
        input: IAdvancedList.DownloadAdvancedListItemAttachmentInput
    ): Promise<APIResponse<IAdvancedList.DownloadAdvancedListItemAttachmentOutput>>;
    generateSandboxPutUrl(
        input: IAdvancedList.GenInstanceSandboxFileGetUrlInput
    ): Promise<APIResponse<IAdvancedList.GenInstanceSandboxFileGetUrlOutput>>;
    generateSandboxPutUrl(
        input: IAdvancedList.GenInstanceSandboxFilePutUrlInput
    ): Promise<APIResponse<IAdvancedList.GenInstanceSandboxFilePutUrlOutput>>;
    loadAllAdvancedListWorkflows(
        input: LoadAllAdvancedListWorkflowsInput
    ): Promise<APIResponse<LoadAllAdvancedListWorkflowsOutput>>;
    loadWorkflow(
        input: IAdvancedList.LoadAdvancedListWorkflowInput
    ): Promise<APIResponse<IAdvancedList.LoadAdvancedListWorkflowOutput>>;
    searchViews(
        input: IAdvancedList.SearchAdvancedListViewsInput
    ): Promise<APIResponse<IAdvancedList.SearchAdvancedListViewsOutput>>;
}

export class AdvancedListAPIImpl implements AdvancedListAPI {
    constructor(private context: AppContextValue) {}

    async load(input: IAdvancedList.LoadAdvancedListInput): Promise<APIResponse<IAdvancedList.LoadAdvancedListOutput>> {
        const response: APIResponse<IAdvancedList.LoadAdvancedListOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/load",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async createItem(
        input: IAdvancedList.CreateAdvancedListItemInput
    ): Promise<APIResponse<IAdvancedList.CreateAdvancedListItemOutput>> {
        const response: APIResponse<IAdvancedList.CreateAdvancedListItemOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/createItem",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async loadItem(input: IAdvancedList.LoadAdvancedListItemInput): Promise<APIResponse<IAdvancedList.LoadAdvancedListItemOutput>> {
        const response: APIResponse<IAdvancedList.LoadAdvancedListItemOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/loadItem",
            toDefaultRequestPayload(input)
        );
        return response;
    }
    async deleteItem(
        input: IAdvancedList.DeleteAdvancedListItemInput
    ): Promise<APIResponse<IAdvancedList.DeleteAdvancedListItemOutput>> {
        const response: APIResponse<IAdvancedList.DeleteAdvancedListItemOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/deleteItem",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async updateItem(
        input: IAdvancedList.UpdateAdvancedListItemInput
    ): Promise<APIResponse<IAdvancedList.UpdateAdvancedListItemOutput>> {
        const response: APIResponse<IAdvancedList.UpdateAdvancedListItemOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/updateItem",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async searchItems(
        input: IAdvancedList.SearchAdvancedListItemsInput
    ): Promise<APIResponse<IAdvancedList.SearchAdvancedListItemsOutput>> {
        const response: APIResponse<IAdvancedList.SearchAdvancedListItemsOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/searchItems",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async batchUploadItemAttachment(
        input: IAdvancedList.BatchUploadAdvancedListItemAttachmentInput
    ): Promise<APIResponse<IAdvancedList.BatchUploadAdvancedListItemAttachmentOutput>> {
        const response: APIResponse<IAdvancedList.BatchUploadAdvancedListItemAttachmentOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/batchUploadAdvancedListItemAttachment",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async batchDeleteItemAttachment(
        input: IAdvancedList.BatchDeleteAdvancedListItemAttachmentInput
    ): Promise<APIResponse<IAdvancedList.BatchDeleteAdvancedListItemAttachmentOutput>> {
        const response: APIResponse<IAdvancedList.BatchDeleteAdvancedListItemAttachmentOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/batchDeleteAdvancedListItemAttachment",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async downloadItemAttachment(
        input: IAdvancedList.DownloadAdvancedListItemAttachmentInput
    ): Promise<APIResponse<IAdvancedList.DownloadAdvancedListItemAttachmentOutput>> {
        const response: APIResponse<IAdvancedList.DownloadAdvancedListItemAttachmentOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/downloadAdvancedListItemAttachment",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async generateSandboxGetUrl(
        input: IAdvancedList.GenInstanceSandboxFileGetUrlInput
    ): Promise<APIResponse<IAdvancedList.GenInstanceSandboxFileGetUrlOutput>> {
        const response: APIResponse<IAdvancedList.GenInstanceSandboxFileGetUrlOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/generateSandboxGetUrl",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async generateSandboxPutUrl(
        input: IAdvancedList.GenInstanceSandboxFilePutUrlInput
    ): Promise<APIResponse<IAdvancedList.GenInstanceSandboxFilePutUrlOutput>> {
        const response: APIResponse<IAdvancedList.GenInstanceSandboxFilePutUrlOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/generateSandboxPutUrl",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async loadAllAdvancedListWorkflows(
        input: LoadAllAdvancedListWorkflowsInput
    ): Promise<APIResponse<LoadAllAdvancedListWorkflowsOutput>> {
        const allWorkflows: EntityWorkflowSearchItem[] = [];
        let pageIndex = 1;
        do {
            const searchInput: IAdvancedList.SearchAdvancedListWorkflowInput = {
                query: {
                    propertyTokens: [
                        {
                            name: "ref.entityExtraRef.entityRef.entityId",
                            value: input.listRef.entityId,
                            operator: ComparisonOperator.EQUALS,
                            isKeywordText: true
                        }
                    ]
                },
                pagination: {
                    pageIndex: pageIndex,
                    pageSize: 100
                },
                by: input.by
            };
            const searchOutput: APIResponse<IAdvancedList.SearchAdvancedListWorkflowOutput> = await RestAPI.post(
                "custom-domain",
                "/advanced-list/searchAdvancedListWorkflows",
                toDefaultRequestPayload(searchInput)
            );
            const output = APIOutput.fromRaw<IAdvancedList.SearchAdvancedListWorkflowOutput>(searchOutput.data);
            if (output.isErr()) {
                throw new Error(output.err.message);
            }
            allWorkflows.push(...output.data.items);
            if (output.data.total <= allWorkflows.length) {
                break;
            }
            pageIndex++;
        } while (true);

        const ret: LoadAllAdvancedListWorkflowsOutput = {
            body: allWorkflows
        };

        return {
            statusText: "Success",
            httpStatusCode: 200,
            data: APIOutput.createSuccessOutput(ret)
        };
    }

    async loadWorkflow(
        input: IAdvancedList.LoadAdvancedListWorkflowInput
    ): Promise<APIResponse<IAdvancedList.LoadAdvancedListWorkflowOutput>> {
        const response: APIResponse<IAdvancedList.LoadAdvancedListWorkflowOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/loadAdvancedListWorkflow",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async searchViews(
        input: IAdvancedList.SearchAdvancedListViewsInput
    ): Promise<APIResponse<IAdvancedList.SearchAdvancedListViewsOutput>> {
        const response: APIResponse<IAdvancedList.SearchAdvancedListViewsOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/searchViews",
            toDefaultRequestPayload(input)
        );
        return response;
    }
}