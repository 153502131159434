import { Amplify, Auth } from "aws-amplify";
import { ClientConfig } from "../setup/config";

export class AmplifyFactory {
    public static createAuth (config: ClientConfig) {
        const oauth = {
            domain: config.cognito.domain,
            scope: config.cognito.scope,
            redirectSignIn: config.cognito.signInRedirectUrl,
            redirectSignOut: config.cognito.signOutRedirectUrl,
            responseType: config.cognito.responseType
        };
        Auth.configure({
            region: config.awsRegion,
            userPoolId: config.cognito.userPoolName,
            userPoolWebClientId: config.cognito.clientId,
            oauth
        });
        return Auth;
    }

    public static createRestAPI (config: ClientConfig) {
        const header = async () => {
            return {
                Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`
            };
        };
        Amplify.API.configure({
            endpoints: [
                {
                name: "custom-domain",
                endpoint: config.apiEndpoint.customDomain,
                custom_header: header
                }
            ]
        });
        return Amplify.API;
    }
}
