import {
    ContainerRef,
    DecisionTreeContent,
    NodeAnswerDisplayType,
    RichTextContent
} from "@amzn/ask-legal-domain";
import * as React from "react";
import {
    Box,
    Button,
    ColumnLayout,
    SpaceBetween,
    Spinner
} from "@amzn/awsui-components-react";
import { RichTextContentView } from "./RichTextContentView";
import { ButtonAnswer } from "../../decision-tree-container/view-components/ButtonAnswer";
import { DropdownAnswers } from "../../decision-tree-container/view-components/DropdownAnswers";
import { AppContext } from "../../../setup/context";
import { useAPI2 } from "../../../hooks/api-hook";
import { ErrorFlashbar } from "../../common/ErrorFlashbar";

export const DecisionTreeContentView = (props: {
    content: DecisionTreeContent;
    containerRef: ContainerRef;
}) => {
    const context = React.useContext(AppContext);
    const loadNodeApiRunner = useAPI2(
        context.getDecisionTreeNodeAPI().get
    );

    const [viewContent, setViewContent] = React.useState<React.ReactNode>(
        <RichTextContentView
            content={
                props.content.welcomeText
                    ? props.content.welcomeText
                    : RichTextContent.create("Click on start to begin")
            }
        />
    );

    const [actionButtons, setActionButtons] = React.useState<{answer: string; nodeId: string}[]>([{
        answer: "Start",
        nodeId: props.content.rootNodeId
    }]);
    const [parentNodeId, setParentNodeId] = React.useState<string>(null);
    const [currentNodeId, setCurrentNodeId] = React.useState<string>(null);
    const [answerDisplayStyle, setAnswerDisplayStyle] = React.useState<NodeAnswerDisplayType>(
        NodeAnswerDisplayType.Buttons
    );

    const restartDecisionTree = () => {
        setViewContent(
            <RichTextContentView
                content={
                    props.content.welcomeText
                        ? props.content.welcomeText
                        : RichTextContent.create("Click on start to begin")
                }
            />
        );
        setActionButtons([{
            answer: "Start",
            nodeId: props.content.rootNodeId
        }]);
        setCurrentNodeId(null);
        setParentNodeId(null);
        setAnswerDisplayStyle(NodeAnswerDisplayType.Buttons);
    };

    const getNode = (nodeId: string) => {
        loadNodeApiRunner.invoke({
            containerRef: props.containerRef,
            nodeId: nodeId
        });
    };

    const getAnswerLayout = () => {
        if (answerDisplayStyle === NodeAnswerDisplayType.Dropdown) {
            return <div className="center-div">
                <DropdownAnswers
                    answers={actionButtons}
                    onAnswerSelected={getNode}
                    disabled={loadNodeApiRunner.status !== "Succeeded"}
                />
            </div>;
        }
        if (answerDisplayStyle === NodeAnswerDisplayType.Buttons) {
            const answerButtons = actionButtons.map(x => <ButtonAnswer
                nodeId={x.nodeId}
                answer={x.answer}
                onClickHandler={getNode}
                loading={loadNodeApiRunner.status === "Running"}
                disabled={loadNodeApiRunner.status === "Error"}
            />);
            if (actionButtons.length === 1) {
                return <div className="vertical-center">{answerButtons}</div>;
            } else if (actionButtons.length === 2) {
                return <ColumnLayout columns={2}>
                    {answerButtons.map(val => <div className="vertical-center">{val}</div>)}
                </ColumnLayout>;
            } else {
                return <ColumnLayout columns={3} borders="vertical">
                    {answerButtons}
                </ColumnLayout>;
            }
        }
    };

    React.useEffect(() => {
        if (loadNodeApiRunner.status === "Succeeded") {
            setViewContent(<RichTextContentView content={loadNodeApiRunner.output.content} />);
            const currentNode = props.content.allNodes.find(node => node.id === loadNodeApiRunner.input.nodeId);
            setActionButtons(currentNode.children);
            setParentNodeId(currentNode.parentNodeId);
            setCurrentNodeId(loadNodeApiRunner.input.nodeId);
            setAnswerDisplayStyle(currentNode.answerDisplayType || NodeAnswerDisplayType.Buttons);
        } else if (loadNodeApiRunner.status === "Running") {
            setViewContent(<Box textAlign="center" variant="awsui-key-label">
                <Spinner /> <em>Fetching Content</em>
            </Box>);
        } else if (loadNodeApiRunner.status === "Error") {
            setViewContent(
                <Box variant="div" textAlign="center" margin="xxxl" padding="xxxl">
                    <ErrorFlashbar error={loadNodeApiRunner.err} action={
                        <Button onClick={() => getNode(loadNodeApiRunner.input.nodeId)}>Retry</Button>
                    }/>
                </Box>
            );
            setActionButtons([]);
            setParentNodeId(currentNodeId);
            setCurrentNodeId(loadNodeApiRunner.input.nodeId);
            setAnswerDisplayStyle(NodeAnswerDisplayType.Buttons);
        }
    }, [loadNodeApiRunner.status]);

    return <>
        <SpaceBetween size="m" direction="vertical">
            <Box textAlign="right">
                {currentNodeId && <Button
                    iconName="refresh"
                    onClick={restartDecisionTree}
                    loading={loadNodeApiRunner.status === "Running"}
                    disabled={loadNodeApiRunner.status !== "Succeeded"}
                >Restart</Button>}
                {currentNodeId && parentNodeId && <ButtonAnswer
                    nodeId={parentNodeId}
                    answer="Go Back"
                    onClickHandler={getNode}
                    loading={loadNodeApiRunner.status === "Running"}
                    disabled={loadNodeApiRunner.status === "Error"}
                    actionType="previous"
                />}
            </Box>
            <div style={{minHeight: "200px"}}>
                {viewContent}
            </div>
            {actionButtons.length > 0 && <hr />}
            <Box textAlign="center">
                {getAnswerLayout()}
            </Box>
        </SpaceBetween>
    </>;
};