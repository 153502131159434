import { EntityRef, IUserProfile, UserProfile } from "@amzn/ask-legal-domain";
import { API as RestAPI } from "aws-amplify";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface UserProfileAPI {
    favor(input: EntityRef): Promise<APIResponse<UserProfile>>;
    unfavor(input: EntityRef): Promise<APIResponse<UserProfile>>;
    create(input: IUserProfile.CreateUserProfileInput): Promise<APIResponse<UserProfile>>;
    loadFavorites(input: IUserProfile.LoadFavoritesInput): Promise<APIResponse<IUserProfile.LoadFavoritesOutput>>;
    load(input: string): Promise<APIResponse<UserProfile>>;
    loadRecentViews(input: IUserProfile.LoadRecentViewsInput): Promise<APIResponse<IUserProfile.LoadRecentViewsOutput>>;
    reorderFavorites(input: IUserProfile.ReorderFavoriteInput): Promise<APIResponse<UserProfile>>;
}

export class UserProfileAPIImpl implements UserProfileAPI {
    constructor (private context: AppContextValue) {}

    async loadRecentViews(
        input: IUserProfile.LoadRecentViewsInput
    ): Promise<APIResponse<IUserProfile.LoadRecentViewsOutput>> {
        const response: APIResponse<IUserProfile.LoadRecentViewsOutput> = await RestAPI.post(
            "custom-domain",
            "/user/load-recent-views",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async favor(input: EntityRef): Promise<APIResponse<UserProfile>> {
        const response: APIResponse<UserProfile> = await RestAPI.post(
            "custom-domain",
            "/user/favor",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async unfavor(input: EntityRef): Promise<APIResponse<UserProfile>> {
        const response: APIResponse<UserProfile> = await RestAPI.post(
            "custom-domain",
            "/user/unfavor",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async create(input: IUserProfile.CreateUserProfileInput): Promise<APIResponse<UserProfile>> {
        const response: APIResponse<UserProfile> = await RestAPI.post(
            "custom-domain",
            "/user/create",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async loadFavorites(input: IUserProfile.LoadFavoritesInput): Promise<APIResponse<IUserProfile.LoadFavoritesOutput>> {
        const response: APIResponse<IUserProfile.LoadFavoritesOutput> = await RestAPI.post(
            "custom-domain",
            "/user/load-favorites",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async load(input: string): Promise<APIResponse<UserProfile>> {
        const response: APIResponse<UserProfile> = await RestAPI.post(
            "custom-domain",
            "/user/load-profile",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async reorderFavorites(input: IUserProfile.ReorderFavoriteInput): Promise<APIResponse<UserProfile>> {
        const response: APIResponse<UserProfile> = await RestAPI.post(
            "custom-domain",
            "/user/reorder-favorites",
            toDefaultRequestPayload(input)
        );
        return response;
    }
}
