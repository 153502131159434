import { Stage } from "@amzn/ask-legal-domain";
import { Stage as LegalAIStage } from "@amzn/legal-ai-companion-types";

export interface ClientConfig {
    apiEndpoint: {
        customDomain: string;
    };
    cognito: {
        userPoolName: string;
        clientId: string;
        domain: string;
        signInRedirectUrl: string;
        signOutRedirectUrl: string;
        responseType: string;
        scope: string[]
    };
    awsRegion: string;
    stage: string;
    legalAICompanion?: {
        url: string;
        stage: LegalAIStage;
        documentGroupId: string;
        instancesConfig: {
            instanceId: string,
            permissionConfg: {
                type: string,
                value: string
            },
            sampleQuestions: string[]
        }[];
    };
}

export class ClientConfigFactory {
    public static async getStage (): Promise<string> {
        const stageSettingFile = "settings.json";
        const stageSettingPath = `${location.origin}/${stageSettingFile}`;
        try {
            const res = await fetch(stageSettingPath);
            const data = await res.json();
            const stage = data.stage;
            return stage;
        } catch (e) {
            console.warn(`Fallback to ${Stage.Local}`);
            return Stage.Local;
        }
    }

    public static async getConfig (): Promise<ClientConfig> {
        const stage = await ClientConfigFactory.getStage();
        const configFile = "stage_config/" + stage + "/config.json";
        const configPath = `${location.origin}/${configFile}`;
        const res = await fetch(configPath);
        const data = await res.json();
        const config: ClientConfig = {
            apiEndpoint: {
                customDomain: data.apiEndpoints.customDomain
            },
            cognito: {
                userPoolName: data.cognito.userPoolName,
                clientId: data.cognito.clientId,
                domain: data.cognito.domain,
                signInRedirectUrl: data.cognito.signInRedirectUrl,
                signOutRedirectUrl: data.cognito.signOutRedirectUrl,
                responseType: data.cognito.responseType,
                scope: data.cognito.scope,
            },
            awsRegion: data.awsRegion,
            stage: stage,
            legalAICompanion: !!data.legalAICompanion && {
                url: data.legalAICompanion.url,
                stage: data.legalAICompanion.stage,
                documentGroupId: data.legalAICompanion.documentGroupId,
                instancesConfig: data.legalAICompanion.instancesConfig,
            }
        };
        return config;
    }
}
