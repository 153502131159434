import { EntityRef, IContainer, Container } from "@amzn/ask-legal-domain";
import { API as RestAPI } from "aws-amplify";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface ContainerAPI {
    load(id: string): Promise<APIResponse<IContainer.LoadContainerOutput>>;
    loadVersion(entityRef: EntityRef): Promise<APIResponse<IContainer.LoadContainerOutput>>;
    batchLoadMetadata(entityRefs: EntityRef[]): Promise<APIResponse<Container.Data[]>>;
}

export class ContainerAPIImpl implements ContainerAPI {
    constructor (private context: AppContextValue) {}

    async batchLoadMetadata(entityRefs: EntityRef[]): Promise<APIResponse<Container.Data[]>> {
        const response: APIResponse<Container.Data[]> = await RestAPI.post(
            "custom-domain",
            "/container/batch-load-meta",
            toDefaultRequestPayload(entityRefs)
        );
        return response;
    }

    async load (id: string): Promise<APIResponse<IContainer.LoadContainerOutput>> {
        const response: APIResponse<IContainer.LoadContainerOutput> = await RestAPI.post(
            "custom-domain",
            "/container/load",
            toDefaultRequestPayload(id)
        );
        return response;
    }

    async loadVersion (entityRef: EntityRef): Promise<APIResponse<IContainer.LoadContainerOutput>> {
        const response: APIResponse<IContainer.LoadContainerOutput> = await RestAPI.post(
            "custom-domain",
            "/container/load-version",
            toDefaultRequestPayload(entityRef)
        );
        return response;
    }
}
