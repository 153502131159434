import { ILegalContact, LegalContact, ServerSidePaginatedLoadingOutput } from "@amzn/ask-legal-domain";
import { APIResponse, toDefaultRequestPayload } from "./common";
import { AppContextValue } from "../setup/context-value";
import { API as RestAPI } from "aws-amplify";

export interface LegalContactAPI {
    create(input: ILegalContact.CreateLegalContactInput): Promise<APIResponse<LegalContact.Record>>;
    update(input: ILegalContact.UpdateLegalContactInput): Promise<APIResponse<LegalContact.Record>>;
    delete(input: ILegalContact.DeleteLegalContactInput): Promise<APIResponse<LegalContact.Record>>;
    loadAllByContainer(input: ILegalContact.LoadAllLegalContactsByContainerInput): Promise<APIResponse<ServerSidePaginatedLoadingOutput<LegalContact.Record>>>;
    loadAll(input: ILegalContact.LoadAllLegalContactsInput): Promise<APIResponse<ServerSidePaginatedLoadingOutput<LegalContact.Record>>>;
    loadAllByUser(input: ILegalContact.LoadAllLegalContactsInput): Promise<APIResponse<ServerSidePaginatedLoadingOutput<ILegalContact.LoadAllLegalContactsByUserOutput>>>;
    load(input: ILegalContact.LoadLegalContactInput): Promise<APIResponse<LegalContact.EntityData>>;
}

export class LegalContactAPIImpl implements LegalContactAPI {
    constructor (private context: AppContextValue) {}

    async create(input: ILegalContact.CreateLegalContactInput): Promise<APIResponse<LegalContact.Record>> {
        const response: APIResponse<LegalContact.Record> = await RestAPI.post(
            "custom-domain",
            "/legal-contact/create",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async update(input: ILegalContact.UpdateLegalContactInput): Promise<APIResponse<LegalContact.Record>> {
        const response: APIResponse<LegalContact.Record> = await RestAPI.post(
            "custom-domain",
            "/legal-contact/update",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async delete(input: ILegalContact.DeleteLegalContactInput): Promise<APIResponse<LegalContact.Record>> {
        const response: APIResponse<LegalContact.Record> = await RestAPI.post(
            "custom-domain",
            "/legal-contact/remove",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async loadAllByContainer(input: ILegalContact.LoadAllLegalContactsByContainerInput): Promise<APIResponse<ServerSidePaginatedLoadingOutput<LegalContact.Record>>> {
        const response: APIResponse<ServerSidePaginatedLoadingOutput<LegalContact.Record>> = await RestAPI.post(
            "custom-domain",
            "/legal-contact/loadAllByContainer",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async loadAll(input: ILegalContact.LoadAllLegalContactsInput): Promise<APIResponse<ServerSidePaginatedLoadingOutput<LegalContact.Record>>> {
        const response: APIResponse<ServerSidePaginatedLoadingOutput<LegalContact.Record>> = await RestAPI.post(
            "custom-domain",
            "/legal-contact/loadAll",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async loadAllByUser(input: ILegalContact.LoadAllLegalContactsInput): Promise<APIResponse<ServerSidePaginatedLoadingOutput<ILegalContact.LoadAllLegalContactsByUserOutput>>> {
        const response: APIResponse<ServerSidePaginatedLoadingOutput<ILegalContact.LoadAllLegalContactsByUserOutput>> = await RestAPI.post(
            "custom-domain",
            "/legal-contact/loadAllByUser",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async load(input: ILegalContact.LoadLegalContactInput): Promise<APIResponse<LegalContact.EntityData>> {
        const response: APIResponse<LegalContact.EntityData> = await RestAPI.post(
            "custom-domain",
            "/legal-contact/load",
            toDefaultRequestPayload(input)
        );
        return response;
    }
}