import { GeneralEntity, IEntity, IMetrics } from "@amzn/ask-legal-domain";
import { API as RestAPI } from "aws-amplify";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface CommonAPI {
    load<T extends GeneralEntity>(input: IEntity.LoadEntityInput): Promise<APIResponse<T>>;
    loadHistory<T>(input: IEntity.LoadEntityHistoryInput): Promise<APIResponse<T>>;
    batchLoad<T>(input: IEntity.BatchLoadEntityInput): Promise<APIResponse<T[]>>;
    batchLoadHistory<T>(input: IEntity.BatchLoadEntityHistoryInput): Promise<APIResponse<T[]>>;
    recordViewHistory(input: IMetrics.RecordViewHistoryInput): Promise<APIResponse<{}>>;
}

export class CommonAPIImpl implements CommonAPI {
    constructor (private context: AppContextValue) {}

    async load<T extends GeneralEntity>(input: IEntity.LoadEntityInput): Promise<APIResponse<T>> {
        const response: APIResponse<T> = await RestAPI.post(
            "custom-domain",
            "/entity/load",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async recordViewHistory(input: IMetrics.RecordViewHistoryInput): Promise<APIResponse<{}>> {
        const response: APIResponse<{}> = await RestAPI.post(
            "custom-domain",
            "/entity/record-view-history",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    loadHistory<T>(input: IEntity.LoadEntityHistoryInput): Promise<APIResponse<T>> {
        throw new Error("Method not implemented.");
    }
    batchLoad<T>(input: IEntity.BatchLoadEntityInput): Promise<APIResponse<T[]>> {
        throw new Error("Method not implemented.");
    }
    batchLoadHistory<T>(input: IEntity.BatchLoadEntityHistoryInput): Promise<APIResponse<T[]>> {
        throw new Error("Method not implemented.");
    }
}
