import { EntityRef, IPageDraft, PageDraft, ServerSidePaginatedLoadingInput, ServerSidePaginatedLoadingOutput } from "@amzn/ask-legal-domain";
import { API as RestAPI } from "aws-amplify";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface PageDraftAPI {
    create(input: IPageDraft.CreatePageDraftInput): Promise<APIResponse<PageDraft.Data>>;
    load(id: string): Promise<APIResponse<PageDraft.Data>>;
    loadVersion(entityRef: EntityRef): Promise<APIResponse<PageDraft.Data>>;
    loadAllByPageId(input: string): Promise<APIResponse<PageDraft.Data[]>>;
    getPageDraftsByStatus(
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<IPageDraft.EntityPageDraftsByStatusOutput>>>;

    updateMeta(input: IPageDraft.UpdatePageDraftMetaInput): Promise<APIResponse<PageDraft.Data>>;
    updateOrder(input: IPageDraft.UpdatePageDraftSectionInput): Promise<APIResponse<PageDraft.Data>>;
    addContainer(input: IPageDraft.AddContainerInput): Promise<APIResponse<PageDraft.Data>>;
    editContainer(input: IPageDraft.EditContainerInput): Promise<APIResponse<PageDraft.Data>>;
    removeContainer(input: IPageDraft.RemoveContainerInput): Promise<APIResponse<PageDraft.Data>>;

    submit(input: string): Promise<APIResponse<PageDraft.Data>>;
    /**
     * @deprecated
     */
    approve(input: string): Promise<APIResponse<PageDraft.Data>>;
    /**
     * @deprecated
     */
    reject(input: string): Promise<APIResponse<PageDraft.Data>>;
    resume(input: string): Promise<APIResponse<PageDraft.Data>>;
    publish(input: string): Promise<APIResponse<IPageDraft.PublishPageDraftOutput>>;
    cancelReview(input: string): Promise<APIResponse<PageDraft.Data>>;

    submit2(input: IPageDraft.SubmitForApprovalInput): Promise<APIResponse<PageDraft.Data>>;
    refresh(draftId: string): Promise<APIResponse<IPageDraft.RefreshApprovalOutput>>;
}

export class PageDraftAPIImpl implements PageDraftAPI {
    constructor(private context: AppContextValue) { }

    async submit2(input: IPageDraft.SubmitForApprovalInput): Promise<APIResponse<PageDraft.Data>> {
        const response: APIResponse<PageDraft.Data> = await RestAPI.post(
            "custom-domain",
            "/page-draft/submit2",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async refresh(draftId: string): Promise<APIResponse<IPageDraft.RefreshApprovalOutput>> {
        const response: APIResponse<IPageDraft.RefreshApprovalOutput> = await RestAPI.post(
            "custom-domain",
            "/page-draft/refresh",
            toDefaultRequestPayload(draftId)
        );
        return response;
    }

    async create(input: IPageDraft.CreatePageDraftInput): Promise<APIResponse<PageDraft.Data>> {
        const response: APIResponse<PageDraft.Data> = await RestAPI.post(
            "custom-domain",
            "/page-draft/create",
            toDefaultRequestPayload(input)
        );
        return response;
    }
    loadAllByPageId(input: string): Promise<APIResponse<PageDraft.Data[]>> {
        throw new Error("Method not implemented.");
    }
    async updateMeta(input: IPageDraft.UpdatePageDraftMetaInput): Promise<APIResponse<PageDraft.Data>> {
        const response: APIResponse<PageDraft.Data> = await RestAPI.post(
            "custom-domain",
            "/page-draft/update-meta",
            toDefaultRequestPayload(input)
        );
        return response;
    }
    async updateOrder(input: IPageDraft.UpdatePageDraftSectionInput): Promise<APIResponse<PageDraft.Data>> {
        const response: APIResponse<PageDraft.Data> = await RestAPI.post(
            "custom-domain",
            "/page-draft/update-order",
            toDefaultRequestPayload(input)
        );
        return response;
    }
    async addContainer(input: IPageDraft.AddContainerInput): Promise<APIResponse<PageDraft.Data>> {
        const response: APIResponse<PageDraft.Data> = await RestAPI.post(
            "custom-domain",
            "/page-draft/add-container",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async editContainer(input: IPageDraft.EditContainerInput): Promise<APIResponse<PageDraft.Data>> {
        const response: APIResponse<PageDraft.Data> = await RestAPI.post(
            "custom-domain",
            "/page-draft/edit-container",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async removeContainer(input: IPageDraft.RemoveContainerInput): Promise<APIResponse<PageDraft.Data>> {
        const response: APIResponse<PageDraft.Data> = await RestAPI.post(
            "custom-domain",
            "/page-draft/remove-container",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async submit(input: string): Promise<APIResponse<PageDraft.Data>> {
        const response: APIResponse<PageDraft.Data> = await RestAPI.post(
            "custom-domain",
            "/page-draft/submit",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async approve(input: string): Promise<APIResponse<PageDraft.Data>> {
        const response: APIResponse<PageDraft.Data> = await RestAPI.post(
            "custom-domain",
            "/page-draft/approve",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async reject(input: string): Promise<APIResponse<PageDraft.Data>> {
        const response: APIResponse<PageDraft.Data> = await RestAPI.post(
            "custom-domain",
            "/page-draft/reject",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async resume(input: string): Promise<APIResponse<PageDraft.Data>> {
        const response: APIResponse<PageDraft.Data> = await RestAPI.post(
            "custom-domain",
            "/page-draft/resume",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async publish(input: string): Promise<APIResponse<IPageDraft.PublishPageDraftOutput>> {
        const response: APIResponse<IPageDraft.PublishPageDraftOutput> = await RestAPI.post(
            "custom-domain",
            "/page-draft/publish",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async cancelReview(input: string): Promise<APIResponse<PageDraft.Data>> {
        const response: APIResponse<PageDraft.Data> = await RestAPI.post(
            "custom-domain",
            "/page-draft/cancel-review",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    discard(input: string): Promise<APIResponse<PageDraft.Data>> {
        throw new Error("Method not implemented.");
    }

    async load(id: string): Promise<APIResponse<PageDraft.Data>> {
        const response: APIResponse<PageDraft.Data> = await RestAPI.post(
            "custom-domain",
            "/page-draft/load",
            toDefaultRequestPayload(id)
        );
        return response;
    }

    async loadVersion(entityRef: EntityRef): Promise<APIResponse<PageDraft.Data>> {
        const response: APIResponse<PageDraft.Data> = await RestAPI.post(
            "custom-domain",
            "/page-draft/load-version",
            toDefaultRequestPayload(entityRef)
        );
        return response;
    }

    async getPageDraftsByStatus(
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<IPageDraft.EntityPageDraftsByStatusOutput>>> {
        const response: APIResponse<ServerSidePaginatedLoadingOutput<IPageDraft.EntityPageDraftsByStatusOutput>> = await RestAPI.post(
            "custom-domain",
            "/page-draft/entityPageDraftsByStatus",
            toDefaultRequestPayload(input)
        );
        return response;
    }
}
