import {
    AdvancedListView,
    EntityRef,
} from "@amzn/altar-sds-client";
import { Select, SelectProps } from "@amzn/awsui-components-react";
import { APIOutput, IAdvancedList } from "@amzn/ask-legal-domain";
import * as React from "react";
import { AppContext } from "../../../setup/context";
import { SDSUtils } from "../../../utils/sds-utils";

export interface ViewsDropdownCompProps {
    advancedListRef: EntityRef;
    selected?: AdvancedListView;
    disabled?: boolean;
    onChange?: (view: AdvancedListView) => void;
}

export const ViewsDropdownComp: React.FC<ViewsDropdownCompProps> = (props) => {
    const context = React.useContext(AppContext);
    const [views, setViews] = React.useState<AdvancedListView[]>([]);
    const [status, setStatus] = React.useState<string>("pending");

    const toOptions = (views: AdvancedListView[]): SelectProps.Options => {
        return views.map((view) => {
            return {
                label: view.name,
                value: view.entityExtraVersionRef.entityExtraRef.extraId
            };
        });
    };

    const init = async () => {
        if (!props.advancedListRef) return;
        try {
            setStatus("loading");
            const apiInput: IAdvancedList.SearchAdvancedListViewsInput = {
                listRepositoryId: props.advancedListRef.repositoryRef.repositoryId,
                listEntityId: props.advancedListRef.entityId,
                by: SDSUtils.getAmazonPersonRef(context.userId),
            };
            const searchViewsOutput = await context.getAdvancedListAPI().searchViews(apiInput);
            const viewsData = APIOutput.fromRaw<IAdvancedList.SearchAdvancedListViewsOutput>(searchViewsOutput.data);
            if (viewsData.isOk()) {
                setViews(viewsData.data.items);
                setStatus("finished");
            } else {
                console.error(`Failed to load views: ${viewsData.err.message}`);
                setStatus("error");
            }
        } catch (err) {
            console.error(err);
            setStatus("error");
        }
    };

    React.useEffect(() => {
        init();
    }, [props.advancedListRef]);

    if (!views.length) {
        return <></>;
    }

    return <Select
        selectedOption={
            props.selected
                ? {
                    label: props.selected.name,
                    value: props.selected.entityExtraVersionRef.entityExtraRef.extraId
                }
                : undefined
        }
        onChange={({ detail }) => {
            const selectedOptionValue = detail.selectedOption.value;
            let selectedView;
            if (selectedOptionValue !== props.selected?.entityExtraVersionRef.entityExtraRef.extraId) {
                selectedView = views.find((view) => view.entityExtraVersionRef.entityExtraRef.extraId === selectedOptionValue);
            }
            props.onChange?.(selectedView);
        }}
        options={toOptions(views)}
        loadingText="Loading views"
        placeholder="Select view"
        filteringType="auto"
        statusType={status}
        disabled={views.length === 0 || props.disabled}
    />;
};