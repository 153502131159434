import { Document, Identity, S3File } from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import { useEffect, useState } from "react";
import { UIModel } from "./ui-model";

export interface FileModel {
    file: File;
    description: string;
    groupTitle?: string;
    warningText?: string;
    errorText?: string;
}

export namespace ContainerModel {
    export const TITLE_CHAR_LIMIT = 100;
    export const DESCRIPTION_CHAR_LIMIT = 140;
    export const DOCUMENT_DESCRIPTION_CHAR_LIMIT = 200;

    export class UploadDocumentState {
        descriptionField: UIModel.State<string>;
        fileField: UIModel.State<File>;
        s3fileKey: S3File.AskLegalFileKey;
        reset: () => void;

        static toDocument(state: UploadDocumentState, s3File: S3File.Data, identity: Identity) {
            return Document.create({
                filename: state.fileField.value.name,
                description: state.descriptionField.value,
                s3File: s3File,
                identity: identity
            });
        }

        static use(props: {
            containerId: string
        }): UploadDocumentState {
            const descriptionField = UIModel.State.use<string>({
                initialValue: ""
            });
            const fileField = UIModel.State.use<File>({
                initialValue: null
            });
            const [s3fileKey, setS3FileKey] = useState<S3File.AskLegalFileKey>(null);

            useEffect(() => {
                if (!!fileField.value && !!fileField.value.name) {
                    setS3FileKey(
                        S3File.AskLegalFileKey.toAskLegalFileKeyFromDocumentFilename(
                            props.containerId, fileField.value.name
                        )
                    );
                } else {
                    setS3FileKey(null);
                }
            }, [fileField.value]);

            const reset = () => {
                descriptionField.setValue("");
                fileField.setValue(null);
            };

            return Builder<UploadDocumentState>(new UploadDocumentState())
                .descriptionField(descriptionField)
                .fileField(fileField)
                .s3fileKey(s3fileKey)
                .reset(reset)
                .build();
        }
    }

    export class UploadMultipleDocumentsState {
        files: UIModel.State<FileModel[]>;
        isValid: () => boolean;
        reset: () => void;

        static toDocument(fileState: FileModel, s3File: S3File.Data, identity: Identity) {
            return Document.create({
                filename: fileState.file.name,
                description: fileState.description,
                s3File: s3File,
                identity: identity
            });
        }

        static toMultipleDocuments(state: UploadMultipleDocumentsState, s3Files: S3File.Data[], identity: Identity) {
            return state.files.value.map((file, index) =>
                Document.create({
                    filename: file.file.name,
                    description: file.description,
                    s3File: s3Files[index],
                    identity: identity
                })
            );
        }

        static use(): UploadMultipleDocumentsState {
            const files = UIModel.State.use<FileModel[]>({ initialValue: [] });
            const reset = () => {
                files.setValue([]);
            };
            const isValid = () => {
                const descriptionsValid = files.value.every((file) => {
                    return file.description.length <= ContainerModel.DOCUMENT_DESCRIPTION_CHAR_LIMIT;
                });
                if (!descriptionsValid) {
                    return false;
                }
                return files.value.every((file) => file.errorText == null);
            };
            return Builder<UploadMultipleDocumentsState>(new UploadMultipleDocumentsState())
                .files(files)
                .isValid(isValid)
                .reset(reset)
                .build();
        }

        static initFile(params: {
            file: File,
            description?: string,
            groupTitle?: string,
            warningText?: string,
            errorText?: string
        }): FileModel {
            return Builder<FileModel>()
                .file(params.file)
                .description(params.description ?? "")
                .groupTitle(params.groupTitle ?? undefined)
                .warningText(params.warningText)
                .errorText(params.errorText)
                .build();
        }
    }
}