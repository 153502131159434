import { Document, DocumentContent, S3File } from "@amzn/ask-legal-domain";
import {
    Box,
    ButtonDropdown,
    ButtonDropdownProps,
    Grid,
    SpaceBetween,
    Spinner
} from "@amzn/awsui-components-react";
import * as React from "react";
import { isEqual } from "lodash";
import { DocumentContentComp } from "./DocumentsContentComp";
import { documentsComparator } from "../../../../utils/common-utils";
import { DocumentGroupExpandable } from "../../document-group/DocumentGroupView";
import "../../../../styles/component/document-group.scss";

interface ViewFilesComponentProps {
    content: DocumentContent;
    resourceIdOfUpdatedLabels: S3File.AskLegalFileKey;
    loading?: boolean;
    onRemove: (document: Document, groupTitle?: string) => void;
    onMove: (document: Document, action: "moveUp" | "moveDown", groupTitle?: string) => void;
    onManageLabels: (document: Document, groupTitle?: string) => void;
}

export const ViewFilesComponent = (props: ViewFilesComponentProps) => {
    const onItemClickHandler = (actionId: string, document: Document, groupTitle?: string) => {
        switch (actionId) {
            case "manageLabels":
                props.onManageLabels(document, groupTitle);
                break;
            case "remove":
                props.onRemove(document, groupTitle);
                break;
            case "moveUp":
                props.onMove(document, "moveUp", groupTitle);
                break;
            case "moveDown":
                props.onMove(document, "moveDown", groupTitle);
                break;
            default:
                break;
        }
    };

    const actionButtonDefaultItems: ButtonDropdownProps.Items = [
        { text: "Manage labels", id: "manageLabels" },
        { text: "Remove", id: "remove" },
    ];

    const actionButtonSortItems: ButtonDropdownProps.Items = [{
        text: "Re-Order",
        items: [
            { text: "Move Up", id: "moveUp" },
            { text: "Move Down", id: "moveDown" }
        ]
    }];

    const compActionButtonDropdown = (document: Document, groupTitle?: string) => <ButtonDropdown
        loading={props.loading}
        items={
            !props.content?.manualSort ?
                actionButtonDefaultItems : actionButtonDefaultItems.concat(actionButtonSortItems)
        }
        onItemClick={(e) => onItemClickHandler(e.detail.id, document, groupTitle)}
    >
        Actions
    </ButtonDropdown>;

    return (
        <Box margin={{ left: "m", right: "m" }}>
            <SpaceBetween size="s">
                <hr />
                <Grid gridDefinition={[
                    { colspan: 6 },
                    { colspan: 3 },
                    { colspan: 3 }
                ]}>
                    <span><b>Document</b></span>
                    <span><b>Labels</b></span>
                    <span><b>Actions</b></span>
                </Grid>
                {props.loading && <div className="vertical-center"><Spinner size="large" /> <em>Loading...</em></div>}
                {props.content?.files && <SpaceBetween size="s">
                    {props.content.files
                        .sort(!props.content?.manualSort ? documentsComparator : undefined)
                        .map((file) => (<DocumentContentComp
                            document={file}
                            reload={isEqual(props.resourceIdOfUpdatedLabels, file.s3File.key)}
                            loading={props.loading}
                            actions={compActionButtonDropdown(file)}
                        />)
                        )
                    }
                </SpaceBetween>}
                <DocumentGroupExpandable
                    manualSort={props.content?.manualSort}
                    fileGroups={props.content?.fileGroups ?? []}
                    FileViewComponent={(params: { document: Document, groupTitle: string }) =>
                        <DocumentContentComp
                            document={params.document}
                            reload={isEqual(props.resourceIdOfUpdatedLabels, params.document.s3File.key)}
                            loading={props.loading}
                            groupTitle={params.groupTitle}
                            actions={compActionButtonDropdown(params.document, params.groupTitle)}
                        />
                    }
                />
            </SpaceBetween>
        </Box>
    );
};