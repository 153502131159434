import * as fileTypeChecker from "file-type-checker";

export namespace FileUtils {
    export enum Extension {
        TXT = "txt",
        XLSX = "xlsx",
        XLS = "xls",
        DOCX = "docx",
        PPTX = "pptx",
        CSV = "csv",
        PDF = "pdf",
        JSON = "json",
        PNG = "png",
        JPG = "jpg",
        JPEG = "jpeg",
        XML = "xml",
        HTML = "html",
        NOEXTENSION = "noextension"
    }

    export function getExtension(filename: string): Extension {
        const lastDotIndex = filename.lastIndexOf(".");
        if (lastDotIndex === -1) return Extension.NOEXTENSION;
        return filename.slice(lastDotIndex + 1).toLowerCase() as Extension;
    }

    export function getFileNameWithoutExtension(filename: string): string {
        const lastDotIndex = filename.lastIndexOf(".");
        return lastDotIndex === -1 ? filename : filename.slice(0, lastDotIndex);
    }

    export function getFileNameWithExtension(filename: string, extension: Extension): string {
        return `${filename}.${extension}`;
    }

    export async function isValidFileSignature(file: File): Promise<boolean> {
        // Derived from file-type-checker supported files
        // File signature checking is supported for binary files
        const fileTypesForSignatureCheck = ["jpeg", "png", "rtf", "pdf"];

        const extension = FileUtils.getExtension(file.name);
        const buffer = await Promise.resolve(file.arrayBuffer());
        const fileInfo = fileTypeChecker.detectFile(buffer);
        if (fileInfo?.extension && fileInfo?.extension !== extension) {
            return false;
        }
        if (fileTypesForSignatureCheck.find(f => f === fileInfo?.extension)) {
            const fileCheckerValid = fileTypeChecker.validateFileType(
                buffer, fileTypesForSignatureCheck
            );
            if (!fileCheckerValid) { return false; }
        }
        return true;
    }
}