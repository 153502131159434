import { IRecommendationRule, RecommendationRule, ServerSidePaginatedLoadingInput, ServerSidePaginatedLoadingOutput} from "@amzn/ask-legal-domain";
import { API as RestAPI } from "aws-amplify";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface RecommendationRuleAPI {
    create(input: IRecommendationRule.CreateRecommendationRuleInput): Promise<APIResponse<RecommendationRule.Data>>;
    update(input: IRecommendationRule.UpdateRecommendationRuleInput): Promise<APIResponse<RecommendationRule.Data>>;
    load(id: string): Promise<APIResponse<RecommendationRule.Data>>;
    loadByInstanceId (
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<RecommendationRule.Data>>>;
    remove(id: string): Promise<APIResponse<RecommendationRule.Data>>;
    searchRole(id: IRecommendationRule.SearchRoleInput): Promise<APIResponse<RecommendationRule.Data[]>>;
}

export class RecommendationRuleAPIImpl implements RecommendationRuleAPI {
    constructor (private context: AppContextValue) {}

    async create (
        input: IRecommendationRule.CreateRecommendationRuleInput
    ): Promise<APIResponse<RecommendationRule.Data>> {
        const response: APIResponse<RecommendationRule.Data> = await RestAPI.post(
            "custom-domain",
            "/recommendation/create-rule",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async update (
        input: IRecommendationRule.UpdateRecommendationRuleInput
    ): Promise<APIResponse<RecommendationRule.Data>> {
        const response: APIResponse<RecommendationRule.Data> = await RestAPI.post(
            "custom-domain",
            "/recommendation/update-rule",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async load (
        input: string
    ): Promise<APIResponse<RecommendationRule.Data>> {
        const response: APIResponse<RecommendationRule.Data> = await RestAPI.post(
            "custom-domain",
            "/recommendation/load-rule",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async loadByInstanceId (
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<RecommendationRule.Data>>> {
        const response: APIResponse<ServerSidePaginatedLoadingOutput<RecommendationRule.Data>> = await RestAPI.post(
            "custom-domain",
            "/recommendation/load-rule-by-instance",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async remove(id: string): Promise<APIResponse<RecommendationRule.Data>> {
        const response: APIResponse<RecommendationRule.Data> = await RestAPI.post(
            "custom-domain",
            "/recommendation/delete-rule",
            toDefaultRequestPayload(id)
        );
        return response;
    }

    async searchRole(id: IRecommendationRule.SearchRoleInput): Promise<APIResponse<RecommendationRule.Data[]>> {
        const response: APIResponse<RecommendationRule.Data[]> = await RestAPI.post(
            "custom-domain",
            "/recommendation/search-role",
            toDefaultRequestPayload(id)
        );
        return response;
    }
}
