import { AppLayout, AppLayoutProps, Button, Spinner } from "@amzn/awsui-components-react";
import { Hub } from "aws-amplify";
import * as React from "react";
import { CookiesProvider } from "react-cookie";
import { HashRouter, Route, Switch } from "react-router-dom";
import { AskLegalLayout } from "./components/layout/AskLegalLayout";
import { commonRoutes } from "./pages/common";
import { decisionTreeRoutes } from "./pages/decision-tree";
import { instanceRoutes } from "./pages/instance";
import { legalContactRoutes } from "./pages/legal-contact";
import { pageRoutes } from "./pages/page";
import { pageLibraryRoutes } from "./pages/page-library";
import { recommendationRoutes } from "./pages/recommendation";
import { redirectRoutes } from "./pages/redirects";
import { searchRoutes } from "./pages/search";
import { sharedPageRoutes } from "./pages/shared-page";
import { AppContext } from "./setup/context";
import "./styles/component/layout/AskLegalLayout.scss";
import { useEffect, useRef, useState } from "react";
import { AiCompanionContentPlaceholderComponent, LegalAiCompanionComponent } from "./components/chatbot/legal-ai-companion";
import "./styles/component/chatbot/chatbot.scss";
import legalAIIcon from "../src/components/chatbot/legal-ai-companion-icon.svg";
import { IEntityPermission, EntityType, EntityPermissionOperationNames } from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import { useStyles } from "./components/chatbot/legal-ai-companion-floating-button/styles";
import { useInstanceState } from "./components/instance/instance-store";
import { Overlay } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";


const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

const routes: {
  path: string;
  content: (props: any) => JSX.Element;
  header: () => JSX.Element;
  footer?: () => JSX.Element;
  breadcrumbs?: (props: any) => JSX.Element;
  contentClass?: string;
}[] = [
  ...commonRoutes,
  ...instanceRoutes,
  ...pageLibraryRoutes,
  ...pageRoutes,
  ...searchRoutes,
  ...recommendationRoutes,
  ...decisionTreeRoutes,
  ...legalContactRoutes,
  ...sharedPageRoutes,
  ...redirectRoutes
];

enum AuthStatus {
  Authenticated,
  UnAuthenticated,
  Error
}

const chatbotDrawer: AppLayoutProps.Drawer = {
  id: "LEGAL_AI",
  resizable: true,
  content: <AiCompanionContentPlaceholderComponent />,
  defaultSize: 550,
  ariaLabels: {
    drawerName: "Ai companion",
    closeButton: "Close",
    resizeHandle: "Resize",
  },
  trigger: {
    iconName: "star-filled",
  },
};

enum DrawerType {
  LegalAi = "LEGAL_AI",
}

const SESSION_CHATBOT_POPOVER_CONST = "show-chatbot-popup";

export const App = () => {
  const context = React.useContext(AppContext);
  const [showChatbotIcon, setShowChatbotIcon] = useState<boolean>(false);
  const [showChatbotDrawer, setShowChatbotDrawer] = useState<boolean>(false);
  const [showAppLayoutDrawer, setShowAppLayoutDrawer] = useState<boolean>(false);
  const [activeDrawerId, setActiveDrawerId] = useState<string | null>(null);
  const [authStatus, setAuthStatus] = React.useState<AuthStatus>(AuthStatus.UnAuthenticated);
  const { instanceId } = useInstanceState();
  const chatbotPopupSessionStorageValue = sessionStorage.getItem(SESSION_CHATBOT_POPOVER_CONST) || "true";
  const [shouldShowChatbotPopup, setShouldShowChatbotPopup] = useState<boolean>(chatbotPopupSessionStorageValue === "false" ? false : true);

  const init = async () => {
    try {
      Hub.listen("auth", (data) => {
        switch (data.payload.event) {
          case "signIn":
            console.debug("receive signIn event");
            onSignedIn();
            break;
          case "signIn_failure":
            console.debug(`receive signIn failed event with message ${data.payload.message}`);
            signInFailed(data.payload.message);
            break;
          default:
            break;
        }
      });
      await context.init();
      /* Why sleeps 500ms here?
       * This is because we want to wait 500ms to let browser wait the return tokens from Auth,
       * otherwise the browser will re-trigger another sign-in process (because the browser
       * has not get the tokens yet, so browser thinks the user has not yet signed in),
       * and the re-triggered sign-in process will cause unexpected error in Firefox and Safari
       */
      await sleep(500);
      await context.currentUser();
      await onSignedIn();
    } catch (err) {
      console.warn(err);
      if (!window.location.href.includes("code") && !window.location.href.includes("state")) {
        localStorage.setItem("OrignalURLHashBeforeAuth", window.location.href);
      }
      await context.signIn();
    }
  };

  const onSignedIn = async () => {
    await context.getIdentity();
    setAuthStatus(AuthStatus.Authenticated);
    if (localStorage.getItem("OrignalURLHashBeforeAuth")) {
      window.location.href = localStorage.getItem("OrignalURLHashBeforeAuth");
      localStorage.setItem("OrignalURLHashBeforeAuth", "");
    }
  };

  const signInFailed = async (message: string) => {
    console.error(message);
    setAuthStatus(AuthStatus.Error);
  };

  React.useEffect(() => {
    init();
  }, []);

  const content = () => {
    return routes.map((route) => (
      <Route
        exact
        key={route.path}
        path={route.path}
        render={(props) => (
          <div className="awsui">
            <AskLegalLayout
              header={<route.header />}
              content={
                <div className={route.contentClass ? route.contentClass : "sherpa-content"}>
                  {!!route.breadcrumbs ? <route.breadcrumbs {...props} /> : null}
                  <route.content {...props} />
                </div>
              }
              footer={!!route.footer ? <route.footer /> : null}
            />
          </div>
        )}
      />
    ));
  };

  /**
   * Intercept user confirmations and track events for history forward/backward
   * On failed confirmation revert to old path
   */
  const getUserConfirmation = (payload: string, callback: (ok: boolean) => void) => {
    const { action, message } = JSON.parse(payload);
    const confirmed = window.confirm(message);
    callback(confirmed);
    // POP action is called for history backward & forward operations
    // Forward operation will atleast be blocked to transitioning page resources
    // for now. URL change still exists. Needs a fix for forward url change.
    if (!confirmed && action === "POP") {
      // push url forward if user tries to navigate back in history
      window.history.forward();
    }
  };

  const isUserAdmin = async (instanceId: string): Promise<boolean> => {
    try {
      const isAdminOutput = await context
        .getEntityPermissionAPI()
        .isAuthorized(
          Builder<IEntityPermission.IsAuthorizedInput>()
            .id(instanceId)
            .type(EntityType.Instance)
            .permission(EntityPermissionOperationNames.CAN_ADMIN)
            .build()
        );
      return (isAdminOutput.data as unknown as any).data;
    } catch (e) {
      console.error(`Error checking isAdmin`, e);
      return false;
    }
  };

  const isUserMembertOfRole = async (roleId: string): Promise<boolean> => {
    try {
      const roleMembershipOutput = await context
        .getEntityPermissionAPI()
        .isAuthorized(
          Builder<IEntityPermission.IsAuthorizedInput>()
            .id(roleId)
            .type(EntityType.Role)
            .permission(EntityPermissionOperationNames.CAN_VIEW)
            .build()
        );
      return (roleMembershipOutput.data as unknown as any).data;
    } catch (e) {
      console.error(`Error checking if user is member of role`, e);
      return false;
    }
  };

  const shouldShowChatbot = async (instanceId: string) => {
    try {
      const stageConfig = context.config;
      const instanceConfig = stageConfig.legalAICompanion?.instancesConfig?.find(
        (instanceConfig) =>
          instanceConfig.instanceId.toLowerCase() === instanceId.toLowerCase()
      );
      if (instanceConfig) {
        const instancePermissionConfgType =
          instanceConfig.permissionConfg?.type?.toLowerCase();
        switch (instancePermissionConfgType) {
          case "instance-role":
            const isUserMemberOfRoleResponse = await isUserMembertOfRole(
              instanceConfig.permissionConfg?.value
            );
            setShowChatbotIcon(isUserMemberOfRoleResponse);
            break;
          case "instance-admin":
            const isUserAdminResponse = await isUserAdmin(instanceId);
            setShowChatbotIcon(isUserAdminResponse);
            break;
          case "instance-view":
            setShowChatbotIcon(true);
        }
      } else {
        setShowChatbotIcon(false);
      }
    } catch (e) {
      console.error(`Error checking if chatbot should be shown`, e);
      setShowChatbotIcon(false);
    }
  };

  const onDrawerChange = (drawerEvent) => {
    setShowChatbotDrawer(!showChatbotDrawer);
    setShowChatbotIcon(!showChatbotIcon);
    setActiveDrawerId(drawerEvent.detail.activeDrawerId);
  };

  useEffect(() => {
    setShowAppLayoutDrawer(!!showChatbotDrawer);
    if (!!showChatbotDrawer) {
      setActiveDrawerId(DrawerType.LegalAi);
    }
  }, [!!showChatbotDrawer]);

  useEffect(() => {
    if (instanceId) {
      shouldShowChatbot(instanceId);
    }
  }, [instanceId]);


  const handleShowChatbotButtonClick = () => {
    setShowChatbotDrawer(true);
    setShowChatbotIcon(false);
  };

  const handleChatbotPopoverClick = () => {
    sessionStorage.setItem(SESSION_CHATBOT_POPOVER_CONST, "false");
    setShouldShowChatbotPopup(false);
    setShowChatbotDrawer(true);
    setShowChatbotIcon(false);
  };

  const { floatingButton } = useStyles();
  const buttonRef = useRef(null);
  const [show, setShow] = React.useState(true);
  const handleClose = () => setShow(false);

  return (
    <>
      {authStatus === AuthStatus.UnAuthenticated && (
        <span
          className="awsui-util-status-inactive vertical-center horizontal-center"
          style={{ paddingTop: "25px" }}
        >
          <Spinner size="large" /> Loading Resources
        </span>
      )}
      {authStatus === AuthStatus.Error && (
        <div>
          Failed to load. Please try to refresh the page. If the issue still
          persists after multiple tries. Please contact altar-dev@amazon.com for
          support.
        </div>
      )}
      {authStatus === AuthStatus.Authenticated && (
        <CookiesProvider>
          <AppContext.Provider value={context}>
            <AppLayout
              toolsHide={true}
              drawers={!!showAppLayoutDrawer ? [chatbotDrawer] : []}
              activeDrawerId={activeDrawerId}
              onDrawerChange={(event) => {
                onDrawerChange(event);
              }}
              navigationHide={true}
              disableContentPaddings={true}
              content={
                <>
                  <div>
                    <LegalAiCompanionComponent
                      instanceId={instanceId}
                    />
                    {showChatbotIcon && !activeDrawerId && (
                      <>
                        <button
                          type="button"
                          className={floatingButton}
                          onClick={handleShowChatbotButtonClick}
                          aria-label="Legal AI Companion"
                          ref={buttonRef}
                        >
                          <img src={legalAIIcon} alt="Legal Ai" />
                        </button>
                        {shouldShowChatbotPopup && (
                          <Overlay
                            show={show}
                            target={buttonRef}
                            placement="left"
                            containerPadding={20}
                          >
                            <Popover className="chatbot-popover">
                              <Popover.Title className="chatbot-popover-title d-flex justify-content-between align-items-center">
                                <span>New GenAI feature!</span>
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  style={{ cursor: "pointer" }}
                                  onClick={handleClose}
                                  size="lg"
                                />
                              </Popover.Title>
                              <Popover.Content>
                                <div>
                                  <div className="chatbot-popover-content-section">
                                    <img
                                      src={legalAIIcon}
                                      alt="Legal Ai"
                                      className="chatbot-popover-content-section-image"
                                    />
                                    <h6>
                                      Want some help from Pathfinder Chatbot?
                                    </h6>
                                  </div>
                                  <div>
                                    <p>
                                      I can answer questions from Pathfinder.
                                    </p>
                                    <p>
                                      I can also help you perform GenAI
                                      capabilities on content from each page,
                                      like generating a summary.
                                    </p>
                                  </div>
                                  <hr />
                                  <Button
                                    ariaLabel="Chatbot Get started"
                                    iconName="gen-ai"
                                    className="chatbot-popover-button"
                                    type="button"
                                    onClick={handleChatbotPopoverClick}
                                  >
                                    Get started
                                  </Button>
                                </div>
                              </Popover.Content>
                            </Popover>
                          </Overlay>
                        )}
                      </>
                    )}
                  </div>
                  <HashRouter getUserConfirmation={getUserConfirmation}>
                    <Switch>{content()}</Switch>
                  </HashRouter>
                </>
              }
            />
          </AppContext.Provider>
        </CookiesProvider>
      )}
    </>
  );
};