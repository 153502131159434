import {
    AcceptedExtension,
    APIOutput,
    DocumentContent,
    Identity,
    IFile,
    MIMEType,
    S3File
} from "@amzn/ask-legal-domain";
import {
    Box,
    Button,
    ExpandableSection,
    Flashbar,
    Form,
    FormField,
    Header,
    ProgressBar,
    Select,
    SelectProps,
    SpaceBetween,
    StatusIndicator,
    Textarea
} from "@amzn/awsui-components-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { FileUpload } from "../../../common/FileUpload";
import { Accept, FileRejection } from "react-dropzone/.";
import { ContainerModel, FileModel } from "../../../../model/container-model";
import { AppContext } from "../../../../setup/context";

interface UploadFilesComponentProps {
    containerId: string;
    userIdentity: Identity;
    value: DocumentContent;
    onChange: (value: DocumentContent) => void;
}

export const UploadFilesComponent = (props: UploadFilesComponentProps) => {
    const MAX_FILE_UPLOAD_LIMIT = 10;
    const ACCEPTED_FILE_TYPES: Accept = Object.assign(
        {},
        ...AcceptedExtension.map((extension: string) => {
            return { [extension]: [MIMEType[extension]] };
        })
    );
    const context = React.useContext(AppContext);
    const [uploadProgress, setUploadProgress] = React.useState<number>(-1);
    const [errorMessages, setErrorMessages] = React.useState<string[]>([]);

    const state = ContainerModel.UploadMultipleDocumentsState.use();

    const uploadFile = async (fileModel: FileModel): Promise<S3File.Data | undefined> => {
        const s3FileKey = S3File.AskLegalFileKey.toAskLegalFileKeyFromDocumentFilename(
            props.containerId,
            fileModel.file.name
        );
        const rawOutput = await context.getFileAPI().getPresignedUrl(
            IFile.PresignedUrlInput.create({
                key: s3FileKey,
                operation: IFile.PresignedOperation.Put,
            })
        );
        const s3PresignedUrlOutput = APIOutput.fromRaw<IFile.PresignedUrlOutput>(rawOutput.data);
        if (s3PresignedUrlOutput.isErr()) {
            throw new Error(s3PresignedUrlOutput.err.message);
        }
        const uploadViaPresignedUrlRawOutput = await context.getFileAPI().uploadViaPresignedUrl({
            data: fileModel.file,
            presignedUrl: s3PresignedUrlOutput.data.presignedUrl
        });
        if (uploadViaPresignedUrlRawOutput.data.isErr()) {
            throw new Error(uploadViaPresignedUrlRawOutput.data.err.message);
        }
        const presignedUrlOutput = uploadViaPresignedUrlRawOutput.data.data;
        const versionId = presignedUrlOutput.headers["x-amz-version-id"];
        const s3File = S3File.Data.create({
            bucket: s3PresignedUrlOutput.data.bucket,
            key: s3FileKey,
            versionId: versionId,
            contentType: S3File.ContentType.ContainerRichTextContent
        });
        return s3File;
    };

    const uploadFiles = async () => {
        if (!state.files.value.length) {
            return;
        }
        setUploadProgress(0);
        let updatedDocumentContent = props.value;
        for await (const fileToUpload of state.files.value) {
            try {
                const uploadedS3File = await uploadFile(fileToUpload);
                if (uploadedS3File) {
                    updatedDocumentContent = DocumentContent.addOrReplaceFile(
                        updatedDocumentContent,
                        ContainerModel.UploadMultipleDocumentsState.toDocument(
                            fileToUpload, uploadedS3File, props.userIdentity
                        ),
                        fileToUpload.groupTitle
                    );
                }
            } catch (error) {
                setErrorMessages((prev) => [
                    ...prev,
                    `Error uploading ${fileToUpload.file.name}`
                ]);
            } finally {
                setUploadProgress((prev) => prev + 1);
            }
        }
        props.onChange(updatedDocumentContent);
        state.reset();
        setUploadProgress(-1);
    };

    const validateFileNameIsNotDuplicate = (fileName: string, groupTitle?: string): string => {
        if (!props.value) {
            return;
        }
        if (groupTitle) {
            const duplicateInGroup = props.value.fileGroups.find(e => e.groupTitle === groupTitle)?.files.some(f => f.filename === fileName);
            if (duplicateInGroup) {
                return `File already exists in ${groupTitle}`;
            }
            return;
        } else {
            if (props.value.files.some(e => e.filename === fileName)) {
                return "File already exists";
            }
        }
    };

    const onFileDrop = (
        acceptedFiles: File[],
        rejectedFiles: FileRejection[],
        _event: any
    ) => {
        setErrorMessages([]);
        if (rejectedFiles.length > 0) {
            rejectedFiles.forEach((rejectedFile) => {
                setErrorMessages((prev) => [
                    ...prev,
                    `${rejectedFile.file.name
                    } cannot be uploaded, reason: ${JSON.stringify(
                        rejectedFile.errors.map((err) => err.code)
                    )}`
                ]);
            });
        }
        if (acceptedFiles.length === 0) {
            return;
        }

        state.files.setValue(acceptedFiles.map((file) =>
            ContainerModel.UploadMultipleDocumentsState.initFile({
                file,
                warningText: validateFileNameIsNotDuplicate(file.name)
            })
        ));
    };

    const fileGroupOptions: SelectProps.Options = [
        { label: "No group", value: "" },
        ...((props.value?.fileGroups ?? []).map(g => ({
            label: g.groupTitle,
            value: g.groupTitle
        })))
    ];

    const onFileMetadataChangeHandler = (file: FileModel, index: number) => {
        state.files.setValue(
            state.files.value.map((f, i) => i === index ? file : f)
        );
    };

    const getUploadProgress = () => {
        if (uploadProgress < 0) {
            return 0;
        }
        return (uploadProgress / state.files.value.length) * 100;
    };

    return <>
        <SpaceBetween size="s">
            {!!errorMessages.length &&
                <Flashbar
                    items={errorMessages.map((msg) => ({
                        type: "error",
                        dismissible: true,
                        content: msg,
                    }))}
                />
            }
            {state.files.value?.some(f => f.warningText === "File already exists") &&
                <Flashbar
                    items={[{
                        type: "info",
                        dismissible: false,
                        content: (
                            <>
                                A file with the same name already exists in this container. Uploading will overwrite the existing file.
                            </>
                        ),
                    }]}
                />
            }
            {!state.files.value.length &&
                <FileUpload.Comp
                    onDrop={onFileDrop}
                    acceptedFileTypes={ACCEPTED_FILE_TYPES}
                    maxFiles={MAX_FILE_UPLOAD_LIMIT}
                />}
            {!!state.files.value.length &&
                <SpaceBetween size="s">
                    <Box margin={{ left: "l", right: "l" }}>
                        <Form
                            actions={
                                <SpaceBetween size="s" direction="vertical">
                                    <SpaceBetween size="s" direction="horizontal">
                                        <Button
                                            onClick={() => {
                                                state.reset();
                                                setErrorMessages([]);
                                            }}
                                            loading={uploadProgress >= 0}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={uploadFiles}
                                            loading={uploadProgress >= 0}
                                            disabled={!state.isValid()}
                                        >
                                            Begin Upload
                                        </Button>
                                    </SpaceBetween>
                                </SpaceBetween>
                            }
                            header={<Header>Upload Files</Header>}
                        >
                            <SpaceBetween size="s">
                                {state.files.value.map((file, index) =>
                                    <ExpandableSection
                                        defaultExpanded={!index}
                                        key={file.file.name}
                                        headerText={
                                            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                <FontAwesomeIcon
                                                    icon={faFile}
                                                    title={`File Name: ${file.file.name}, Size: ${file.file.size} bytes, Type: ${file.file.type}`}
                                                    style={{ fontSize: "1.2rem", color: "gray" }}
                                                />
                                                <span>{file.file.name}</span>
                                                {file.warningText && <span><StatusIndicator type="warning">{file.warningText}</StatusIndicator></span>}
                                            </div>
                                        }
                                    >
                                        <SpaceBetween size="s">
                                            <FormField
                                                label="Description"
                                                errorText={file.description.length > ContainerModel.DOCUMENT_DESCRIPTION_CHAR_LIMIT &&
                                                    `Exceeded character limit`
                                                }
                                                constraintText={`Maximum ${ContainerModel.DOCUMENT_DESCRIPTION_CHAR_LIMIT} characters`}
                                            >
                                                <Textarea
                                                    placeholder="Enter file description"
                                                    value={file.description}
                                                    onChange={(e) => {
                                                        onFileMetadataChangeHandler(
                                                            {
                                                                ...file,
                                                                description: e.detail.value
                                                            },
                                                            index
                                                        );
                                                    }}
                                                    disabled={uploadProgress >= 0}
                                                />
                                            </FormField>
                                            <FormField
                                                label="File Group"
                                            >
                                                <Select
                                                    selectedOption={
                                                        fileGroupOptions.find(f => f.value === file.groupTitle) ?? fileGroupOptions[0]
                                                    }
                                                    onChange={(e) => {
                                                        onFileMetadataChangeHandler(
                                                            {
                                                                ...file,
                                                                groupTitle: e.detail.selectedOption.value,
                                                                warningText: validateFileNameIsNotDuplicate(
                                                                    file.file.name,
                                                                    e.detail.selectedOption.value
                                                                )
                                                            },
                                                            index
                                                        );
                                                    }}
                                                    options={fileGroupOptions}
                                                    selectedAriaLabel="Selected"
                                                    disabled={uploadProgress >= 0}
                                                />
                                            </FormField>
                                        </SpaceBetween>
                                    </ExpandableSection>
                                )}
                                {uploadProgress >= 0 && <ProgressBar
                                    value={getUploadProgress()}
                                    label={`Uploading... ${Math.round(getUploadProgress())}%`}
                                />}
                            </SpaceBetween>
                        </Form>
                    </Box>
                </SpaceBetween>}
        </SpaceBetween>
    </>;
};