import {
    EmployeeIdentity,
    Identity,
    UserSearchInterface
} from "@amzn/ask-legal-domain";
import { API as RestAPI } from "aws-amplify";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface UserSearchAPI {
    searchUserByLogin(
        input: UserSearchInterface.SearchUserByLoginInput
    ): Promise<APIResponse<EmployeeIdentity[]>>;
    searchUserByName(
        input: UserSearchInterface.SearchUserByNameInput
    ): Promise<APIResponse<Identity[]>>;
    getUserByLogin(
        input: UserSearchInterface.GetUserByLoginInput
    ): Promise<APIResponse<EmployeeIdentity>>;
}

export class UserSearchAPIImpl implements UserSearchAPI {
    constructor (private context: AppContextValue) {}

    async searchUserByLogin(
        input: UserSearchInterface.SearchUserByLoginInput
    ): Promise<APIResponse<EmployeeIdentity[]>> {
        const response: APIResponse<EmployeeIdentity[]> = await RestAPI.post(
            "custom-domain",
            "/user-search/by-login",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async searchUserByName(
        input: UserSearchInterface.SearchUserByNameInput
    ): Promise<APIResponse<Identity[]>> {
        const response: APIResponse<Identity[]> = await RestAPI.post(
            "custom-domain",
            "/user-search/by-name",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async getUserByLogin(
        input: UserSearchInterface.GetUserByLoginInput
    ): Promise<APIResponse<EmployeeIdentity>> {
        const response: APIResponse<EmployeeIdentity> = await RestAPI.post(
            "custom-domain",
            "/user-search/getUserByLogin",
            toDefaultRequestPayload(input)
        );
        return response;
    }
}