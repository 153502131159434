import { PageLibrary, PageLibraryInterface, ServerSidePaginatedLoadingInput, ServerSidePaginatedLoadingOutput } from "@amzn/ask-legal-domain";
import { API as RestAPI } from "aws-amplify";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface PageLibraryAPI {
    create(input: PageLibraryInterface.CreatePageLibraryInput): Promise<APIResponse<PageLibrary>>;
    update(input: PageLibraryInterface.UpdatePageLibraryInput): Promise<APIResponse<PageLibrary>>;
    load(id: string): Promise<APIResponse<PageLibrary>>;
    loadAll(
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<PageLibrary>>>;
    searchByName(
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<PageLibrary>>>;
    delete(id: string): Promise<APIResponse<{}>>;
    isLegalOnly(id: string): Promise<APIResponse<boolean>>;
}

export class PageLibraryAPIImpl implements PageLibraryAPI {
    constructor(private context: AppContextValue) { }

    async delete(id: string): Promise<APIResponse<{}>> {
        const response: APIResponse<{}> = await RestAPI.post(
            "custom-domain",
            "/page-library/delete",
            toDefaultRequestPayload(id)
        );
        return response;
    }

    async loadAll(
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<PageLibrary>>> {
        const response: APIResponse<ServerSidePaginatedLoadingOutput<PageLibrary>> = await RestAPI.post(
            "custom-domain",
            "/page-library/load-all",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async searchByName(
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<PageLibrary>>> {
        const response: APIResponse<ServerSidePaginatedLoadingOutput<PageLibrary>> = await RestAPI.post(
            "custom-domain",
            "/page-library/searchByName",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async create(
        input: PageLibraryInterface.CreatePageLibraryInput
    ): Promise<APIResponse<PageLibrary>> {
        const response: APIResponse<PageLibrary> = await RestAPI.post(
            "custom-domain",
            "/page-library/create",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async update(
        input: PageLibraryInterface.UpdatePageLibraryInput
    ): Promise<APIResponse<PageLibrary>> {
        const response: APIResponse<PageLibrary> = await RestAPI.post(
            "custom-domain",
            "/page-library/update",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async load(
        input: string
    ): Promise<APIResponse<PageLibrary>> {
        const response: APIResponse<PageLibrary> = await RestAPI.post(
            "custom-domain",
            "/page-library/load",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async isLegalOnly(
        id: string
    ): Promise<APIResponse<boolean>> {
        const response: APIResponse<boolean> = await RestAPI.post(
            "custom-domain",
            "/page-library/is-legalonly",
            toDefaultRequestPayload(id)
        );
        return response;
    }
}
