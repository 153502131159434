import {
    APIOutput,
    AppError,
    EntityType,
    IEntity,
    Instance,
    InstanceFactory,
    PageLibrary,
    PageLibraryFactory
} from "@amzn/ask-legal-domain";
import { Alert, SpaceBetween } from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../setup/context";
import { ErrorFlashbar } from "../../components/common/ErrorFlashbar";
import { BaseRedirectContent } from "./redirect-content/base-redirect-content";
import { UIConstants } from "../../utils/common-utils";
import "../../styles/util-styles.scss";

export const PermissionDenied = (props: {
    entityId: string,
    accessType: "LivePage" | "InstanceAdminPortal" | "PageLibraryAdminPortal" | "PageAdminPortal" | "HomePage",
}) => {
    const context = React.useContext(AppContext);

    const [error, setError] = React.useState<AppError>(null);
    const [instanceMetadata, setInstanceMetadata] = React.useState<Instance>(null);
    const [pageLibraryMetadata, setPageLibraryMetadata] = React.useState<PageLibrary>(null);

    const load = async () => {
        try {
            const loadInstanceRawOutput = await context.getCommonAPI().load(IEntity.LoadEntityInput.create(
                InstanceFactory.fromEntityId(props.entityId),
                EntityType.Instance
            ));
            const loadInstanceOutput = APIOutput.fromRaw<Instance>(loadInstanceRawOutput.data);
            if (loadInstanceOutput.isErr()) {
                setError(loadInstanceOutput.err);
            } else {
                setError(null);
                setInstanceMetadata(loadInstanceOutput.data);
            }

            if (["LivePage", "PageLibraryAdminPortal", "PageAdminPortal"].includes(props.accessType)) {
                const loadPLRawOutput = await context.getCommonAPI().load(
                    IEntity.LoadEntityInput.create(
                        PageLibraryFactory.fromEntityId(props.entityId),
                        EntityType.PageLibrary
                    )
                );
                const loadPageLibraryOutput = APIOutput.fromRaw<PageLibrary>(loadPLRawOutput.data);
                if (loadPageLibraryOutput.isErr()) {
                    setError(loadPageLibraryOutput.err);
                } else {
                    setError(null);
                    setPageLibraryMetadata(loadPageLibraryOutput.data);
                }
            }
        } catch (e) {
            let message = UIConstants.ERROR_MESSAGE;
            if (!!e.response && !!e.response.data && !!e.response.data.message) {
                message = e.response.data.message;
            }
            setError(AppError.create(message, 400));
        }
    };

    React.useEffect(() => {
        load();
    }, []);

    const permissionDeniedContent = (
      <SpaceBetween direction="vertical" size="xxl">
        <div className="font-ember center-text fs-34">
          Oops...Looks like you don't have permission to access this page
        </div>
        <div className="font-ember">
          {!!error && <ErrorFlashbar error={error} />}
          {!error && (
            <Alert
              dismissible={false}
              type="warning"
              header={
                <h4>
                  How to{" "}
                  {instanceMetadata?.permissionRequestTicketTemplate ||
                  pageLibraryMetadata?.permissionRequestTicketTemplate ? (
                    <a
                      target="_blank"
                      href={
                        pageLibraryMetadata?.permissionRequestTicketTemplate ||
                        instanceMetadata?.permissionRequestTicketTemplate
                      }
                    >
                      Request Access
                    </a>
                  ) : (
                    "Request Access"
                  )}{" "}
                  ?
                </h4>
              }
            >
              <div>
                <div>
                  <div>Below information needs to be included in the ticket:</div>
                  <div>1. Which URL you are requesting access to</div>
                  <div>2. What kind of permission you are requesting (read or edit)</div>
                  <div>3. Why do you need access to this page</div>
                </div>
              </div>
            </Alert>
          )}
        </div>
      </SpaceBetween>
    );

    return <BaseRedirectContent children={permissionDeniedContent} contentType={props.accessType} />;
};