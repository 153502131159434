import {
    AdvancedList,
    AdvancedListSearchItem,
    ChoiceOption,
    Pagination as SDS_Pagination,
    Query,
    JoinOperation,
    FieldDefinitionDataType,
    EntityExtraVersionRef,
    ReservedFieldKey,
    AdvancedListView
} from "@amzn/altar-sds-client";
import {
    APIOutput,
    AdvancedListContent,
    ContainerRef,
    EmployeeIdentity,
    IAdvancedList
} from "@amzn/ask-legal-domain";
import {
    Button,
    CollectionPreferences,
    CollectionPreferencesProps,
    Flashbar,
    FlashbarProps,
    Header,
    Pagination,
    PaginationProps,
    SpaceBetween,
    Spinner,
    SpinnerProps,
    Table,
    TableProps,
    PropertyFilter,
    PropertyFilterProps,
    ButtonDropdown,
    Modal,
} from "@amzn/awsui-components-react";
import * as React from "react";
import { AdvancedListPolarisFactory } from "../../../factory/polaris/advanced-list-polaris-factory";
import { AppContext } from "../../../setup/context";
import { Builder } from "builder-pattern";
import { AdvancedListConstants } from "../../../utils/advanced-list.constant";
import {
    CreateAdvancedListItemModal,
    CreateAdvancedListItemModalProps
} from "../../advanced-list/CreateAdvancedListItemModal";
import {
    UpdateAdvancedListItemModal,
    UpdateAdvancedListItemModalProps
} from "../../advanced-list/UpdateAdvancedListItemModal";

import {
    ViewAdvancedListItemModal,
    ViewAdvancedListItemModalProps
} from "../../advanced-list/ViewAdvancedListItemModal";
import {
    DeleteAdvancedListItemModal,
    DeleteAdvancedListItemModalProps
} from "../../advanced-list/DeleteAdvancedListItemModal";
import { BatchDeleteAdvancedListItemModal } from "../../advanced-list/BatchDeleteAdvancedListItemModal";
import { ExportAdvancedListItemsModal } from "../../advanced-list/modal-components/ExportAdvancedListItemsModal";
import { AdvancedListModel } from "../../../model/advanced-list-model";
import { ImportAdvancedListItemsModal } from "../../advanced-list/modal-components/ImportAdvancedListItemsModal";
import { ChangeSummary } from "../edit-content/advanced-list/ChangeSummary";
import { getQueryParameterMap } from "../../../utils/common-utils";
import { SDSUtils } from "../../../utils/sds-utils";
import { AppURLFactory } from "../../../factory/app-url-factory";
import { propertyFilterI18nStrings } from "../../../i18n/propertyFilteringI18nString";
import { ViewsDropdownComp } from "../../advanced-list/view-components/ViewsDropdownComp";

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_INDEX = 1;

export const AdvancedListContentView = (props: {
    content: AdvancedListContent;
    containerRef: ContainerRef;
    showEditControls?: boolean;
}) => {
    const context = React.useContext(AppContext);
    const [spinnerProps, setSpinnerProps] = React.useState<SpinnerProps>();
    const [flashbarProps, setFlashbarProps] = React.useState<Pick<FlashbarProps, "items">>();
    const [tableProps, setTableProps] = React.useState<
        Pick<
            TableProps<AdvancedListSearchItem>,
            "items" | "loading" | "loadingText" | "empty" |
            "columnDefinitions" | "sortingColumn" | "sortingDescending"
        > & { advancedList?: AdvancedList; }
    >();
    const [createItemModalProps, setCreateItemModalProps] = React.useState<CreateAdvancedListItemModalProps>();
    const [updateItemModalProps, setUpdateItemModalProps] = React.useState<UpdateAdvancedListItemModalProps>();
    const [viewItemModalProps, setViewItemModalProps] = React.useState<ViewAdvancedListItemModalProps>();
    const [deleteItemModalProps, setDeleteItemModalProps] = React.useState<DeleteAdvancedListItemModalProps>();
    const [batchDeleteItemModalProps, setBatchDeleteItemModalProps] =
        React.useState<Parameters<typeof BatchDeleteAdvancedListItemModal>[0]>();
    const [exportAdvancedListItemsModalProps, setExportAdvancedListItemsModalProps] =
        React.useState<Parameters<typeof ExportAdvancedListItemsModal>[0]>();
    const [importAdvancedListItemsModalProps, setImportAdvancedListItemsModalProps] =
        React.useState<Parameters<typeof ImportAdvancedListItemsModal>[0]>();
    const [paginationProps, setPaginationProps] = React.useState<
        Pick<PaginationProps, "currentPageIndex" | "pagesCount">
    >({
        currentPageIndex: DEFAULT_PAGE_INDEX,
        pagesCount: 0
    });
    const [collectionPreferences, setCollectionPreferences] = React.useState<Partial<CollectionPreferencesProps>>({
        pageSizePreference: {
            title: "Select page size",
            options: [
                { label: "5", value: 5 },
                { label: "10", value: 10 },
                { label: "25", value: 25 }
            ]
        },
        cancelLabel: "Cancel",
        confirmLabel: "Confirm",
        title: "Table Preferences",
        preferences: {
            pageSize: DEFAULT_PAGE_SIZE
        }
    });
    const [itemCount, setItemCount] = React.useState(0);
    const [propertyFilterProps, setPropertyFilterProps] = React.useState<
        Pick<PropertyFilterProps, "filteringProperties" | "hideOperations" | "filteringOptions"> & {
            query?: Pick<PropertyFilterProps.Query, "operation"> & {
                tokens: AdvancedListPolarisFactory.PropertyFilter.EnrichedFilterToken<any>[];
            };
        }
    >({
        query: { tokens: [], operation: "and" },
        filteringProperties: [],
        filteringOptions: [],
        hideOperations: true
    });
    const [selectedSearchItems, setSelectedSearchItems] = React.useState<AdvancedListSearchItem[]>([]);
    const [exportSpinnerProps, setExportSpinnerProps] = React.useState<SpinnerProps>();
    const [pendingAction, setPendingAction] = React.useState<Record<"viewById", any>>();
    const [currentView, setCurrentView] = React.useState<AdvancedListView>();

    const isLive = window.location.href.includes("/live");

    const getFiltersFromQueryParams = (
        advancedList: AdvancedList
    ): AdvancedListPolarisFactory.PropertyFilter.EnrichedFilterToken<any>[] => {
        const urlParams = getQueryParameterMap();
        const container = urlParams.get("container");
        if (!container || container !== props.containerRef.id) return [];
        const filterFieldKeys = Array.from(new Set(urlParams.keys()))
            .filter(k => k.startsWith(AdvancedListPolarisFactory.PropertyFilter.FILTER_PARAM_PREFIX));
        const tokens: PropertyFilterProps.Token[] = [];
        const visibleFieldKeys = new Set(props.content.visibleFields ?? []);
        filterFieldKeys.forEach((key) => {
            const propertyKey = key.substring(AdvancedListPolarisFactory.PropertyFilter.FILTER_PARAM_PREFIX.length);
            if (!visibleFieldKeys.has(propertyKey)) return;
            tokens.push({
                propertyKey,
                value: urlParams.get(key),
                operator: "=",
            });
        });
        return AdvancedListPolarisFactory.PropertyFilter.toTokens(
            tokens,
            advancedList.fieldDefinitions
        );
    };

    const init = async () => {
        if (!props.content?.entityRef) {
            // New blank container draft
            setFlashbarProps(undefined);
            return;
        }

        try {
            setSpinnerProps({});
            const loadOutput = await context.getAdvancedListAPI().load({
                entityId: props.content?.entityRef.entityId,
                repositoryId: props.content?.entityRef.repositoryRef.repositoryId,
                by: SDSUtils.getAmazonPersonRef(context.userId)
            });

            let sortingColumn: TableProps.ColumnDefinition<AdvancedListSearchItem> | undefined;
            let sortingDescending = false;
            let columnDefinitions: TableProps.ColumnDefinition<AdvancedListSearchItem>[] = [];
            let advancedList: AdvancedList;
            let emptyText: string;

            const output = APIOutput.fromRaw<IAdvancedList.LoadAdvancedListOutput>(loadOutput.data);
            if (output.isOk()) {
                // Have existing AL
                advancedList = Builder(output.data.body)
                    .fieldDefinitions(output.data.body.fieldDefinitions.filter((def) => !def.deprecated))
                    .build();
                if (isLive) {
                    const visibleFieldDefinitions = AdvancedListPolarisFactory.Table.filterFieldDefinitionsByVisibility(
                        advancedList.fieldDefinitions ?? [],
                        props.content.visibleFields ?? []
                    );

                    columnDefinitions = [
                        ...AdvancedListPolarisFactory.Table.buildColumnDefinitions(
                            visibleFieldDefinitions,
                            props.content.fieldConfigurations,
                            props.content.visibleFields ?? []
                        )
                    ];

                    const defaultFilterTokens = getFiltersFromQueryParams(advancedList);
                    if (defaultFilterTokens.length) {
                        setPropertyFilterProps((prev) => ({
                            ...prev,
                            query: {
                                ...prev.query,
                                tokens: defaultFilterTokens
                            }
                        }));

                        // Set pending action to view item by ID
                        if (defaultFilterTokens.find(f => f.propertyKey === ReservedFieldKey.ID)) {
                            setPendingAction({
                                viewById: defaultFilterTokens.find(f => f.propertyKey === ReservedFieldKey.ID).value
                            });
                        }
                    }
                } else {
                    columnDefinitions = [
                        ...AdvancedListPolarisFactory.Table.buildColumnDefinitions(
                            AdvancedListPolarisFactory.Table.updateFieldDefinitions(
                                props.content.updateAdvancedListPayload.reorderFieldDefinitionSequence?.length ?
                                    AdvancedListModel.sortAdvancedListFieldDefinitions(
                                        advancedList.fieldDefinitions, props.content.updateAdvancedListPayload.reorderFieldDefinitionSequence
                                    ) : advancedList?.fieldDefinitions || [],
                                props.content.updateAdvancedListPayload
                                    .addFieldDefinitionsList ?? []
                            ).filter((def) => !def.deprecated),
                            props.content.fieldConfigurations,
                            props.content.visibleFields ?? []
                        ),
                    ];
                }
                const defaultSort = advancedList?.defaultSort;
                if (defaultSort?.propertyName) {
                    sortingColumn = columnDefinitions.find((col) => col.id === defaultSort.propertyName);
                    sortingDescending = defaultSort.descending;
                }
            } else if (output.err.code === 400 && isLive) {
                // No existing AL and is on live page
                emptyText = "List is under creation. Try again after a few seconds.";
            }

            if (!isLive) {
                // Draft mode. Add all pending columns
                columnDefinitions.push(
                    ...AdvancedListPolarisFactory.Table.addFieldDefinitions(
                        advancedList?.fieldDefinitions ?? [],
                        props.content?.updateAdvancedListPayload
                            ?.addFieldDefinitionsList || []
                    ).map<TableProps.ColumnDefinition<AdvancedListSearchItem>>(
                        (f) => ({
                            header: `${f.displayName} (pending)`,
                            id: f.displayName,
                            cell: (item) => <></>,
                        })
                    )
                );
            }

            setTableProps((prev) => ({
                ...prev,
                advancedList: advancedList,
                columnDefinitions: [...columnDefinitions],
                sortingColumn: sortingColumn,
                sortingDescending,
                loading: false,
                empty: emptyText
            }));

            if (advancedList) {
                setPropertyFilterProps((prev) => ({
                    ...prev,
                    filteringProperties: AdvancedListPolarisFactory.PropertyFilter.getFilterProperties(
                        advancedList.fieldDefinitions
                    )
                }));
            }
            setFlashbarProps(undefined);
        } catch (err) {
            setFlashbarProps({
                items: [
                    {
                        type: "error",
                        content: (err as Error).message
                    }
                ]
            });
        } finally {
            setSpinnerProps(undefined);
        }
    };

    const searchItems = async () => {
        try {
            setTableProps((prev) => ({
                ...prev,
                loading: true,
                loadingText: "loading items"
            }));
            const paginationEffective = {
                pageIndex: paginationProps.currentPageIndex || DEFAULT_PAGE_INDEX,
                pageSize: collectionPreferences.preferences?.pageSize || DEFAULT_PAGE_SIZE
            };
            const searchItemsOutput = await context.getAdvancedListAPI().searchItems({
                listEntityId: props.content.entityRef.entityId,
                listRepositoryId: props.content.entityRef.repositoryRef.repositoryId,
                containerId: props.containerRef.id,
                pagination: Builder<SDS_Pagination>()
                    .pageSize(paginationEffective.pageSize)
                    .pageIndex(paginationEffective.pageIndex)
                    .build(),
                query: Builder<Query>()
                    .operation(JoinOperation.AND)
                    .propertyTokens(
                        propertyFilterProps.query.tokens.map((t) =>
                            AdvancedListPolarisFactory.PropertyFilter.convertEnhancedPropertyFilterTokenToFilterQueryValue(
                                t,
                                tableProps.advancedList.fieldDefinitions
                            )
                        )
                    )
                    .build(),
                sort: tableProps.sortingColumn?.sortingField && {
                    propertyName: tableProps.sortingColumn.sortingField,
                    descending: tableProps.sortingDescending || false
                },
                by: SDSUtils.getAmazonPersonRef(context.userId)
            });

            const output = APIOutput.fromRaw<IAdvancedList.SearchAdvancedListItemsOutput>(searchItemsOutput.data);
            if (output.isErr()) {
                setTableProps((prev) => ({
                    ...prev,
                    loading: false,
                    loadingText: output.err.message
                }));
            } else {
                setTableProps((prev) => ({
                    ...prev,
                    loading: false,
                    loadingText: undefined,
                    items: output.data.items,
                    empty: "No items found"
                }));
                setItemCount(output.data.total);

                setPaginationProps((prev) => ({
                    ...prev,
                    pagesCount: Math.ceil(output.data.total! / paginationEffective.pageSize)
                }));

                // Check for pending action to view by ID
                if (pendingAction?.viewById !== undefined) {
                    // Clear pending actions
                    setPendingAction(undefined);
                    const item = output.data.items.find(
                        item => {
                            const idField = item.values.find(field => field.key === ReservedFieldKey.ID);
                            return idField && idField.value.toString() === pendingAction.viewById.toString();
                        }
                    );
                    if (item) {
                        setViewItemModalProps({
                            itemRef: item.entityExtraVersionRef,
                            advancedList: tableProps.advancedList,
                            fieldConfigurations: props.content.fieldConfigurations,
                            containerRef: props.containerRef
                        });
                    }
                }
            }
        } catch (err) {
            setTableProps((prev) => ({
                ...prev,
                loading: false,
                loadingText: (err as Error).message
            }));
        } finally {
            setTableProps((prev) => ({
                ...prev,
                loading: false
            }));
        }
    };

    const loadFilterOptions = async (detail: PropertyFilterProps.LoadItemsDetail) => {
        if (!detail.filteringProperty) return;
        if (!detail.filteringOperator) return;

        const fieldDef = tableProps.advancedList.fieldDefinitions.find(
            (e) => e.fieldKey === detail.filteringProperty.key
        );

        if (!fieldDef) return;
        if (fieldDef.dataType === FieldDefinitionDataType.choice) {
            setPropertyFilterProps((prev) => ({
                ...prev,
                filteringOptions:
                    AdvancedListPolarisFactory.PropertyFilter.getFilterOptions<ChoiceOption>(
                        fieldDef,
                        fieldDef.choiceOptions,
                        (t) => t.displayValue
                    ),
                filteringStatusType: "finished"
            }));
            return;
        }
        if (fieldDef.dataType === FieldDefinitionDataType.boolean) {
            setPropertyFilterProps((prev) => ({
                ...prev,
                filteringOptions:
                    AdvancedListPolarisFactory.PropertyFilter.getFilterOptions<AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES>(
                        fieldDef,
                        [
                            AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.Yes,
                            AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.No
                        ]
                    ),
                filteringStatusType: "finished"
            }));
            return;
        }
        if (
            fieldDef.dataType === FieldDefinitionDataType.IdentityRef ||
            fieldDef.dataType === FieldDefinitionDataType.multiIdentityRef
        ) {
            if (!detail.filteringText || detail.filteringText.trim().length < 3) {
                return;
            }
            setPropertyFilterProps((prev) => ({
                ...prev,
                filteringStatusType: "loading"
            }));
            const userSearchOutput = await context.getUserSearchAPI().searchUserByLogin({
                limit: 20,
                userName: detail.filteringText.trim()
            });
            const output = APIOutput.fromRaw<EmployeeIdentity[]>(userSearchOutput.data);
            if (output.isOk()) {
                setPropertyFilterProps((prev) => ({
                    ...prev,
                    filteringOptions: AdvancedListPolarisFactory.PropertyFilter.getFilterOptions<EmployeeIdentity>(
                        fieldDef,
                        output.data,
                        (t) => `${t.name} (${t.id})`
                    ),
                    filteringStatusType: "finished"
                }));
            } else {
                setPropertyFilterProps((prev) => ({
                    ...prev,
                    filteringOptions: [],
                    filteringStatusType: "error",
                    filteringErrorText: output.err.message
                }));
            }
            return;
        }
    };

    const handleQueryChange = async (detail: PropertyFilterProps.Query) => {
        if (!detail) return;
        const tokens = AdvancedListPolarisFactory.PropertyFilter.toTokens(
            detail.tokens,
            tableProps.advancedList.fieldDefinitions
        );
        setPropertyFilterProps((prev) => ({
            ...prev,
            query: {
                ...prev.query,
                operation: detail.operation,
                tokens: tokens
            }
        }));
    };

    const onItemAction = (item: AdvancedListSearchItem, action: AdvancedListPolarisFactory.Table.ItemActionType) => {
        switch (action) {
            case "edit":
                setUpdateItemModalProps({
                    itemRef: item.entityExtraVersionRef,
                    advancedList: tableProps.advancedList,
                    containerRef: props.containerRef
                });
                break;
            case "view":
                setViewItemModalProps({
                    itemRef: item.entityExtraVersionRef,
                    advancedList: tableProps.advancedList,
                    fieldConfigurations: props.content.fieldConfigurations,
                    containerRef: props.containerRef
                });
                break;
            case "delete":
                setDeleteItemModalProps({
                    itemRef: item.entityExtraVersionRef,
                    containerRef: props.containerRef
                });
                break;
            case "link":
                const idField = item.values.find((e) => e.key === ReservedFieldKey.ID);
                if (!idField) break;
                const filterKey = AdvancedListPolarisFactory.PropertyFilter.FILTER_PARAM_PREFIX + ReservedFieldKey.ID;
                const filterValue = (idField.value as Number).toString();
                const linkToItem = window.location.origin + AppURLFactory.getContainer(
                    props.containerRef.id,
                    {
                        params: new Map([
                            [filterKey, filterValue]
                        ])
                    }
                );
                navigator.clipboard.writeText(linkToItem);
                break;
            default:
                break;
        }
    };

    const onViewChange = (view: AdvancedListView) => {
        setCurrentView(view);
        let viewColumnDefinitions: TableProps.ColumnDefinition<AdvancedListSearchItem>[] = [];
        let sort: Pick<TableProps<AdvancedListSearchItem>, "sortingColumn" | "sortingDescending"> = {
            sortingColumn: undefined,
            sortingDescending: undefined
        };
        let filterQuery: PropertyFilterProps.Query;
        if (view) {
            const viewFieldDefinitions = tableProps.advancedList?.fieldDefinitions?.filter(
                (field) => view.fieldKeys?.includes(field.fieldKey)
            );
            viewColumnDefinitions = [
                ...AdvancedListPolarisFactory.Table.buildColumnDefinitions(
                    viewFieldDefinitions ?? [],
                    props.content.fieldConfigurations,
                    view.fieldKeys ?? []
                )
            ];
            if (view.sort?.length) {
                sort = {
                    sortingColumn: { sortingField: view.sort[0].propertyName },
                    sortingDescending: view.sort[0].descending,
                };
            }
            if (view.filter) {
                filterQuery = AdvancedListPolarisFactory.PropertyFilter.convertFilterQueryToEnhancedPropertyFilterQuery(
                    view.filter,
                    tableProps.advancedList.fieldDefinitions
                );
            }
        } else {
            const visibleFieldDefinitions = AdvancedListPolarisFactory.Table.filterFieldDefinitionsByVisibility(
                tableProps.advancedList?.fieldDefinitions ?? [],
                props.content.visibleFields ?? []
            );
            // reset column definitions
            viewColumnDefinitions = [
                ...AdvancedListPolarisFactory.Table.buildColumnDefinitions(
                    visibleFieldDefinitions,
                    props.content.fieldConfigurations,
                    props.content.visibleFields ?? []
                )
            ];
            // reset filters
            filterQuery = {
                operation: "and",
                tokens: []
            };
        }
        setTableProps((prev) => {
            return {
                ...prev,
                ...sort,
                columnDefinitions: viewColumnDefinitions,
            };
        });
        handleQueryChange(filterQuery);
    };

    React.useEffect(() => {
        setPaginationProps((prev) => ({
            ...prev,
            currentPageIndex: DEFAULT_PAGE_INDEX,
            pagesCount: Math.ceil(itemCount / collectionPreferences?.preferences?.pageSize)
        }));
    }, [collectionPreferences.preferences?.pageSize]);

    React.useEffect(() => {
        init();
    }, []);

    React.useEffect(() => {
        if (tableProps?.advancedList) {
            searchItems();
        }
    }, [
        tableProps?.advancedList,
        tableProps?.sortingColumn,
        tableProps?.sortingDescending,
        paginationProps.currentPageIndex,
        collectionPreferences?.preferences?.pageSize,
        propertyFilterProps?.query
    ]);

    return (
        <>
            {spinnerProps && <Spinner />}
            {flashbarProps && <Flashbar {...flashbarProps} />}
            {exportSpinnerProps && <Modal visible={true}><Spinner /></Modal>}
            {exportAdvancedListItemsModalProps && (
                <ExportAdvancedListItemsModal
                    {...exportAdvancedListItemsModalProps}
                    onCanceled={() => {
                        setExportAdvancedListItemsModalProps(undefined);
                    }}
                    onConfirm={() => {
                        setExportAdvancedListItemsModalProps(undefined);
                        setSelectedSearchItems([]);
                    }}
                />
            )}
            {createItemModalProps && (
                <CreateAdvancedListItemModal
                    {...createItemModalProps}
                    fieldConfigurations={props.content.fieldConfigurations}
                    onCreated={(item) => {
                        setTableProps((prev) => ({
                            ...prev,
                            items: [item, ...prev?.items]
                        }));
                        setCreateItemModalProps(undefined);
                    }}
                    onCanceled={() => {
                        setCreateItemModalProps(undefined);
                    }}
                    containerRef={props.containerRef}
                />
            )}
            {updateItemModalProps && (
                <UpdateAdvancedListItemModal
                    {...updateItemModalProps}
                    fieldConfigurations={props.content.fieldConfigurations}
                    onUpdated={(item) => {
                        setTableProps((prev) => ({
                            ...prev,
                            items: [
                                item,
                                ...prev.items.filter(
                                    (e) =>
                                        e.entityExtraVersionRef.entityExtraRef.extraId !==
                                        item.entityExtraVersionRef.entityExtraRef.extraId
                                )
                            ]
                        }));
                        setUpdateItemModalProps(undefined);
                    }}
                    onCanceled={() => {
                        setUpdateItemModalProps(undefined);
                    }}
                />
            )}
            {viewItemModalProps && (
                <ViewAdvancedListItemModal
                    {...viewItemModalProps}
                    onCanceled={() => {
                        setViewItemModalProps(undefined);
                    }}
                />
            )}
            {deleteItemModalProps && (
                <DeleteAdvancedListItemModal
                    {...deleteItemModalProps}
                    onDeleted={(itemRef: EntityExtraVersionRef) => {
                        setTableProps((prev) => ({
                            ...prev,
                            items: [
                                ...prev.items.filter(
                                    (e) =>
                                        e.entityExtraVersionRef.entityExtraRef.extraId !==
                                        itemRef.entityExtraRef.extraId
                                )
                            ]
                        }));
                        setDeleteItemModalProps(undefined);
                    }}
                    onCanceled={() => {
                        setDeleteItemModalProps(undefined);
                    }}
                />
            )}
            {batchDeleteItemModalProps && (
                <BatchDeleteAdvancedListItemModal
                    {...batchDeleteItemModalProps}
                    onDeleted={(itemRefList: EntityExtraVersionRef[]) => {
                        setTableProps((prev) => ({
                            ...prev,
                            selectedItems: [],
                            items: [
                                ...prev.items.filter(
                                    (e) =>
                                        !itemRefList.some(
                                            (deletedRef) =>
                                                deletedRef.entityExtraRef.extraId ===
                                                e.entityExtraVersionRef.entityExtraRef.extraId
                                        )
                                )
                            ]
                        }));
                        setBatchDeleteItemModalProps(undefined);
                        setSelectedSearchItems([]);
                    }}
                    onCanceled={() => {
                        setBatchDeleteItemModalProps(undefined);
                    }}
                />
            )}
            {importAdvancedListItemsModalProps && (
                <ImportAdvancedListItemsModal
                    {...importAdvancedListItemsModalProps}
                    onCreated={(items) => {
                        setTableProps((prev) => ({
                            ...prev,
                            items: [...items, ...prev.items]
                        }));
                        setImportAdvancedListItemsModalProps(undefined);
                    }}
                    onCanceled={() => setImportAdvancedListItemsModalProps(undefined)}
                />
            )}
            {!isLive &&
                <ChangeSummary advancedList={tableProps?.advancedList} content={props.content} />
            }
            {tableProps && (
                <Table
                    {...tableProps}
                    selectionType="multi"
                    columnDefinitions={[
                        ...AdvancedListPolarisFactory.Table.buildReservedColumnDefinition([...tableProps.columnDefinitions], onItemAction),
                        AdvancedListPolarisFactory.Table.buildActionColumnDefinition(onItemAction)
                    ]}
                    header={
                        <Header
                            actions={
                                <SpaceBetween size="s" direction="horizontal">
                                    <ViewsDropdownComp
                                        advancedListRef={props.content.entityRef}
                                        selected={currentView}
                                        onChange={onViewChange}
                                        disabled={!!spinnerProps}
                                    />
                                    <Button
                                        disabled={selectedSearchItems?.length < 1}
                                        iconName="remove"
                                        onClick={() => {
                                            setBatchDeleteItemModalProps({
                                                itemRefList: selectedSearchItems.map((item) => item.entityExtraVersionRef!),
                                                containerRef: props.containerRef
                                            });
                                        }}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        variant="primary"
                                        disabled={!tableProps.advancedList}
                                        iconName="add-plus"
                                        onClick={() => {
                                            setCreateItemModalProps({
                                                listRef: props.content?.entityRef,
                                                advancedList: tableProps.advancedList,
                                                containerRef: props.containerRef
                                            });
                                        }}
                                    >
                                        New Item
                                    </Button>
                                    <ButtonDropdown
                                        items={[
                                            { text: "Export to Excel", id: "export_items", disabled: false },
                                            { text: "Import from Excel", id: "import_items", disabled: false }
                                        ]}
                                        onItemClick={(e) => {
                                            if (e.detail.id === "export_items") {
                                                setExportAdvancedListItemsModalProps((prev) => ({
                                                    ...prev,
                                                    containerRef: props.containerRef,
                                                    advancedListEntityRef: props.content.entityRef,
                                                    fieldConfigurations: props.content.fieldConfigurations,
                                                    selectedItems: selectedSearchItems.map((item) => item.entityExtraVersionRef.entityExtraRef!)
                                                }));
                                            } else if (e.detail.id === "import_items") {
                                                setImportAdvancedListItemsModalProps((prev) => ({
                                                    ...prev,
                                                    containerRef: props.containerRef,
                                                    advancedListRef: props.content.entityRef,
                                                    fieldConfigurations: props.content.fieldConfigurations
                                                }));
                                            }
                                        }}
                                    >
                                        Actions
                                    </ButtonDropdown>
                                </SpaceBetween>
                            }
                        />
                    }
                    pagination={
                        <Pagination
                            {...paginationProps}
                            ariaLabels={{
                                nextPageLabel: "Next page",
                                previousPageLabel: "Previous page",
                                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`
                            }}
                            onChange={(e) => {
                                setPaginationProps((prev) => ({
                                    ...prev,
                                    pagesCount: Math.ceil(itemCount / collectionPreferences.preferences.pageSize),
                                    currentPageIndex: e.detail.currentPageIndex
                                }));
                            }}
                        />
                    }
                    preferences={
                        <CollectionPreferences
                            {...collectionPreferences}
                            onConfirm={(e) => {
                                setCollectionPreferences((prev) => ({ ...prev, preferences: e.detail }));
                            }}
                        />
                    }
                    filter={
                        <PropertyFilter
                            {...propertyFilterProps}
                            disableFreeTextFiltering={false}
                            onChange={(e) => {
                                handleQueryChange(e.detail);
                            }}
                            onLoadItems={(e) => {
                                loadFilterOptions(e.detail);
                            }}
                            i18nStrings={propertyFilterI18nStrings}
                        />
                    }
                    onSortingChange={(e) => {
                        setTableProps((prev) => ({
                            ...prev,
                            sortingColumn: e.detail.sortingColumn,
                            sortingDescending: e.detail.isDescending
                        }));
                    }}
                    onSelectionChange={({ detail }) => {
                        setSelectedSearchItems(detail.selectedItems);
                    }}
                    selectedItems={selectedSearchItems}
                    stickyColumns={{ first: 0, last: 1 }}
                />
            )}
        </>
    );
};
